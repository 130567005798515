<template>
  <form @submit="onSubmit" novalidate>
    <slot
      :errors="errors"
      :values="values"
      :meta="meta"
      :isSubmitting="isSubmitting"
      :setFieldValue="setFieldValue"
    />
  </form>
</template>

<script setup>
import { useForm } from "vee-validate";

const props = defineProps({
  validationSchema: {
    type: Object,
  },
  initialValues: {
    type: Object,
    default: undefined,
  },
});

const { handleSubmit, errors, values, meta, isSubmitting, setFieldValue } =
  useForm({
    validationSchema: props.validationSchema,
    initialValues: props.initialValues,
  });

const emit = defineEmits(["submit"]);

/**
 * emit submit event with form values and context
 */
const onSubmit = handleSubmit((values, ctx) => {
  emit("submit", values, ctx);
});
</script>
