<template>
  <div class="overlay pt-20 z-[2]">
    <GridContainer
      size="sm"
      class="relative flex h-full flex-col justify-end px-12 pb-6"
    >
      <BaseCloseButton
        class="absolute -right-12 top-0 me-2"
        @click="$emit('onChatClose')"
      />
      <ChatMessagesList :chats="chats" class="py-3 pt-0"/>
      <ChatLoryTyping v-if="isTyping" class="absolute bottom-14 ml-2" />
      <ChatForm @onSubmitForm="handleSubmitForm" class="relative z-10 w-full" />
    </GridContainer>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { DateTime } from "luxon";
import useAuthStore from "@/stores/auth";
import { storeToRefs } from "pinia";
import ChatMessagesList from "@/components/chat/ChatMessagesList.vue";
import ChatForm from "@/components/chat/ChatForm.vue";
import ChatLoryTyping from "@/components/chat/ChatLoryTyping.vue";

import chatsData from "@/data/fake/chats.json";

const { user } = storeToRefs(useAuthStore());
const chats = ref(chatsData);
const isTyping = ref(false);

/**
 * handle chat form submit
 * @param {Object} formData
 */
function handleSubmitForm(formData) {
  chats.value.push({
    id: chats.value.length + 1,
    user: {
      name: user.value.name,
      surname: user.value.surname,
    },
    time: DateTime.now().toFormat("HH:mm"),
    message: formData.message,
  });

  isTyping.value = true;
  const timeout = setTimeout(() => {
    chats.value.push({
      id: chats.value.length + 1,
      time: DateTime.now().toFormat("HH:mm"),
      message: "Hey, how can I help you?",
    });
    clearTimeout(timeout);
    isTyping.value = false;
  }, 2000);
}
</script>
