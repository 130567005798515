<template>
  <div class="space-y-6">
    <section class="mb-8 flex items-center justify-between">
      <div class="flex items-center gap-x-8">
        <BaseBreadcrumbs />
      </div>
      <InputSwitch
        name="debug_mode"
        :label="$t('debugMode')"
        :initial-value="currentIntent.debug_mode"
        @change="toggleDebug(params.id)"
      />
    </section>
    <IntentInformationSection
      :description="currentIntent.description"
      :type="currentIntent.type"
      :latest-usage="currentIntent.latestUsage"
    />
    <IntentContextSection
      :id="currentIntent.id"
      :context="currentIntent.context"
    />
    <IntentExpressionsSection
      :id="currentIntent.id"
      :expressions="currentIntent.Expressions"
    />
    <IntentResponsesSection
      :id="currentIntent.id"
      :responses="currentIntent.Responses"
    />
    <IntentPermissionsSection
      :id="currentIntent.id"
      :permissions="currentIntent.Permissions"
    />
  </div>
</template>

<script setup>
import useIntentStore from "@/stores/intent";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import IntentInformationSection from "@/components/intents/IntentInformationSection.vue";
import IntentContextSection from "@/components/intents/IntentContextSection.vue";
import IntentExpressionsSection from "@/components/intents/IntentExpressionsSection.vue";
import IntentResponsesSection from "@/components/intents/IntentResponsesSection.vue";
import IntentPermissionsSection from "../components/intents/IntentPermissionsSection.vue";

const { fetchIntent, toggleDebug } = useIntentStore();
const { params } = useRoute();
await fetchIntent(params.id);
const { getCurrentIntent: currentIntent } = storeToRefs(useIntentStore());
</script>
