import { createI18n } from "vue-i18n";
import messagesEN from "../lang/en";
import messagesIT from "../lang/it";

const datetimeFormats = {
  "en-US": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  "it-IT": {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "numeric",
      hour12: false,
    },
  },
};
const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "it-IT": {
    currency: {
      style: "currency",
      currency: "EUR",
      useGrouping: true,
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

const i18n = new createI18n({
  datetimeFormats: datetimeFormats,
  numberFormats: numberFormats,
  locale: "en-US",
  allowComposition: true,
  fallbackLocale: "en-US",
  messages: {
    "en-US": messagesEN,
    "it-IT": messagesIT,
  },
  warnHtmlInMessage: "off"
});

export default i18n;
export const globali18n = i18n.global;
