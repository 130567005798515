import axios from "axios";
import middleware401 from "./middleware401";
import useAuthStore from "../stores/auth";

/**
 * Initialize Axios instance to call the API
 * @returns {AxiosInstance}
 */
export const useApi = () => {
  const { VITE_API_HOST, VITE_API_BASE_PATH } = import.meta.env;

  const baseURL = VITE_API_HOST + VITE_API_BASE_PATH;

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  axiosInstance.interceptors.request.use(function (config) {
    const token = useAuthStore().token;
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  axiosInstance.interceptors.response.use((resp) => resp, middleware401);

  axiosInstance.interceptors.response.use(
    (resp) => resp,
    function (error) {
      let errMessage = "Error";

      if (error.response?.data.error.desc) {
        errMessage = error.response.data.error.desc;
      } else if (error.message) {
        errMessage = error.message;
      }

      return Promise.reject(errMessage);
    },
  );

  axiosInstance.interceptors.response.use((resp) => resp.data);

  return axiosInstance;
};
