import { useApi } from "@/api/useAPI.js";

/**
 * Service class for handling authentication related operations.
 */
class AuthService {
  /**
   * The API instance used by the service.
   * @type {Object}
   */
  api = useApi();

  /**
   * The resource endpoint for the 'auth' service.
   * @type {string}
   */
  resource = "auth";

  /**
   * Logs in a user with the provided email and password.
   * @param {Object} credentials - The user's login credentials.
   * @param {string} credentials.userEmail - The user's email.
   * @param {string} credentials.userPassword - The user's password.
   * @returns {Promise} A promise that resolves to the login response.
   */
  async login({ userEmail, userPassword }) {
    return this.api.post(`${this.resource}/login`, {
      userEmail,
      userPassword,
    });
  }

  /**
   * Requests a password reset for the user with the provided email.
   * @param {Object} payload - The user's email.
   * @returns {Promise} A promise that resolves to the password reset request response.
   */
  async forgotPassword(payload) {
    return this.api.post(`${this.resource}/reset/request`, payload);
  }

  /**
   * Resets the user's password with the provided new password.
   * @param {Object} payload - The user's new password.
   * @returns {Promise} A promise that resolves to the password reset response.
   */
  async resetPassword(payload) {
    return this.api.post(`${this.resource}/reset/confirm`, payload);
  }

  /**
   * Logs out the currently authenticated user.
   * @returns {Promise} A promise that resolves to the logout response.
   */
  async logout() {
    return this.api.post(`${this.resource}/logout`);
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
