import { defineStore } from "pinia";
import { OrganizationService } from "@/api/services";

export default defineStore("organizationStore", {
  id: "organization",
  state: () => ({
    organizationList: [],
  }),
  actions: {
    /**
     * Fetch the list of organizations
     */
    async fetchOrganizationList() {
      const { organizationList } = await OrganizationService.readAll();
      this.organizationList = organizationList;
    },
    /**
     * Add a new organization
     * @param {Object} payload
     */
    async addOrganization(payload) {
      const {
        organization: { id },
      } = await OrganizationService.create(payload);
      const { organization } = await OrganizationService.read(id);
      this.organizationList.push(organization);
    },
    /**
     * Update an organization
     * @param {Number} id
     * @param {Object} payload
     */
    async updateOrganization(id, payload) {
      const { organization } = await OrganizationService.update(id, payload);
      this.organizationList = this.organizationList.map((elm) =>
        elm.id === organization.id ? organization : elm,
      );
    },
    /**
     * Delete an organization
     * @param {Number} id
     */
    async deleteOrganization(id) {
      await OrganizationService.delete(id);
      this.organizationList = this.organizationList.filter(
        (elm) => elm.id !== id,
      );
    },
  },
});
