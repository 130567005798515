<template>
  <section class="mb-8 flex items-center justify-between">
    <div class="flex items-center gap-x-8">
      <h1>{{ $t("channels") }}</h1>
      <BaseSearch @on-searching="setFilterText" />
    </div>
    <BaseButton @click="handleNewChannel">{{ $t("addNew") }}</BaseButton>
  </section>
  <BaseDataList
    class="space-y-6"
    :tot-data="filteredData.length"
    :is-searching="!!filterText"
  >
    <ChannelCard
      v-for="channel in filteredData"
      v-bind="channel"
      :key="channel.id"
    />
  </BaseDataList>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useModalStore from "@/stores/modal";
import useChannelStore from "@/stores/channel";
import ChannelCard from "@/components/channels/ChannelCard.vue";
import ChannelForm from "@/components/channels/ChannelForm.vue";
import useFilterData from "@/composables/useFilterData";

const modal = useModalStore();
const { t } = useI18n();

/**
 * Handle new organization event.
 */
function handleNewChannel() {
  modal.openModal({
    title: t("newChannel"),
    component: ChannelForm,
  });
}

/**
 * Filter scope list.
 */
const { channelList } = storeToRefs(useChannelStore());
const { filterText, setFilterText, filteredData } = useFilterData(channelList, [
  "name",
]);
</script>
