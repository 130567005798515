<template>
  <section class="space-y-4">
    <div class="item-center flex justify-between">
      <h2 class="text-base uppercase text-white/75">
        {{ $t("dynamicIntentModules") }}
      </h2>
      <BaseButton :outline="true" size="xs" @click="handleNewDIM">{{
        $t("registerNew")
      }}</BaseButton>
    </div>
    <BaseDataList :totData="dims?.length" tag="div">
      <template #no-data>
        <p class="text-center">{{ $t("noData") }}</p>
      </template>
      <GridRow>
        <GridCol :span-md="6" v-for="dim in dims" :key="dim.id">
          <DynamicIntentCard
            v-bind="dim"
            @on-debug-mode-change="handleDebugModeChange"
            @on-delete-dim="handleDeleteDIM"
          />
        </GridCol>
      </GridRow>
    </BaseDataList>
  </section>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import useModalStore from "@/stores/modal";
import useScopeStore from "@/stores/scope";
import useToastStore from "@/stores/toast";
import DynamicIntentCard from "./DynamicIntentCard.vue";
import DynamicIntentModuleForm from "./DynamicIntentModuleForm.vue";

const props = defineProps({
  scopeID: {
    type: Number,
    required: true,
  },
  dims: {
    type: Array,
    default: () => [],
  },
});

const modal = useModalStore();
const { t } = useI18n();
const { addToast } = useToastStore();
const { toggleDebugDimForScope, deleteDimFromScope } = useScopeStore();

/**
 * Handle new DIM.
 */
function handleNewDIM() {
  modal.openModal({
    title: t("newDIM"),
    component: DynamicIntentModuleForm,
    props: {
      scopeID: props.scopeID,
    },
  });
}

/**
 * Handle debug mode change.
 * @param {Object}
 */
async function handleDebugModeChange({ id }) {
  try {
    await toggleDebugDimForScope(props.scopeID, id);
  } catch (error) {
    addToast({
      content: t("updateErrorMessage"),
      variant: "danger",
    });
  }
}

/**
 * Handle delete DIM.
 * @param {Number} id
 */
async function handleDeleteDIM(id) {
  try {
    await deleteDimFromScope(props.scopeID, id);

    addToast({
      content: t("itemDeleted"),
      variant: "success",
    });
  } catch (error) {
    addToast({
      content: t("deleteErrorMessage"),
      variant: "danger",
    });
  }
}
</script>
