import { ref, computed } from "vue";

/**
 * Custom hook to filter data based on provided keys and filter text.
 *
 * @param {Ref<Array>} data - The data to be filtered.
 * @param {Array} keys - The keys to filter the data on.
 * @returns {Object} - An object containing the setFilterText function and the filteredData computed property.
 */
export default function useFilterData(data, keys = []) {
  const filterText = ref("");

  /**
   * Set the filter text.
   * @param {String} value - The value to set the filter text to.
   */
  function setFilterText(value) {
    filterText.value = value;
  }

  const filteredData = computed(() => {
    return (
      data.value.filter((item) =>
        keys.every((key) =>
          item[key]?.toLowerCase().includes(filterText.value.toLowerCase()),
        ),
      ) || []
    );
  });

  return {
    filterText,
    setFilterText,
    filteredData,
  };
}
