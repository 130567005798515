<template>
  <BaseBoxColor variant="gray" class="w-full space-y-2 p-4">
    <div class="flex items-center justify-between">
      <h3>{{ name }}</h3>
      <BaseButton
        variant="danger"
        size="xs"
        :disabled="!debug_mode"
        @click="handleOnDelete(id)"
        >{{ $t("delete") }}</BaseButton
      >
    </div>
    <h4 class="text-white/75">#{{ code }}</h4>
    <p v-if="description">{{ description }}</p>
    <div>
      <h4 class="text-sm uppercase text-white/75">{{ $t("token") }}</h4>
      <BaseToken :token="token" />
    </div>
    <GridRow>
      <GridCol :span-md="8" class="flex flex-col space-y-4">
        <BaseChart :data="latestUsage" :label="$t('usage')" />
      </GridCol>
      <GridCol :span-md="4" class="flex flex-wrap justify-end gap-x-6 gap-y-2">
        <div>
          <h4 class="text-sm uppercase text-white/75">
            {{ $t("intent") }}
          </h4>
          <strong class="text-xl">{{ intentNumber }}</strong>
        </div>
        <div>
          <h4 class="text-sm uppercase text-white/75">
            {{ $t("expression") }}
          </h4>
          <strong class="text-xl">{{ expressionNumber }}</strong>
        </div>
        <div class="w-full text-end">
          <InputSwitch
            name="debug_mode"
            :label="$t('debugMode')"
            :initial-value="debug_mode"
            @change="emit('onDebugModeChange', { id, debug_mode: !debug_mode })"
          />
        </div>
      </GridCol>
    </GridRow>
  </BaseBoxColor>
</template>

<script setup>
import { defineEmits } from "vue";
import useModalStore from "@/stores/modal";
import { useI18n } from "vue-i18n";

defineProps({
  id: Number,
  name: String,
  code: String,
  description: String,
  token: String,
  debug_mode: Boolean,
  expressionNumber: {
    type: Number,
    default: 0,
  },
  intentNumber: {
    type: Number,
    default: 0,
  },
  latestUsage: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["onDebugModeChange", "onDeleteDim"]);

const modal = useModalStore();

const { t } = useI18n();

/**
 * Handle on delete DIM, open confirm modal.
 * @param {number} id
 */
async function handleOnDelete(id) {
  const response = await modal.openConfirmModal({
    title: t("deleteDIM"),
  });

  if (response) {
    emit("onDeleteDim", id);
  }
}
</script>
