<template>
  <section class="space-y-4">
    <div class="item-center flex justify-between">
      <h3 class="text-base uppercase text-white/75">
        {{ $t("channelGateways") }}
      </h3>
      <BaseButton
        tag="RouterLink"
        :to="{ name: 'channelsIndex' }"
        :outline="true"
        size="xs"
        >{{ $t("manage") }}</BaseButton
      >
    </div>
    <BaseDataList :totData="channelList?.length" tag="div" class="space-y-4">
      <template #no-data>
        <p class="text-center">{{ $t("noData") }}</p>
      </template>
      <ChannelGatewayCard
        v-for="channel in channelList"
        v-bind="channel"
        :key="channel.id"
      />
    </BaseDataList>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { ScopeService } from "@/api/services";
import ChannelGatewayCard from "./ChannelGatewayCard.vue";

const props = defineProps({
  scopeID: {
    type: Number,
    required: true,
  },
});

const channelList = ref([]);
const { channels: data } = await ScopeService.readChannels(props.scopeID);
channelList.value = data;
</script>
