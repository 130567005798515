import BaseService from "./BaseService";

/**
 * Service class for interacting with the 'dim' resource in the API.
 * Extends the BaseService class.
 */
class DynamicIntentModuleService extends BaseService {
  /**
   * The resource endpoint for the 'dim' service.
   * @type {string}
   */
  resource = "dim";

  /**
   * Toggles the debug mode of a dim.
   * @param {number} id The ID of the dim to toggle debug mode for.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async toggleDebug(id) {
    return this.api.patch(`${this.resource}/${id}/toggle`);
  }
}

const dynamicIntentModuleServiceInstance = new DynamicIntentModuleService();

export default dynamicIntentModuleServiceInstance;
