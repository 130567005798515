import { createRouter, createWebHistory } from "vue-router";
import authMiddleware from "./middleware/auth-middleware";

//Importing all views needed in Navigation
import HomePage from "@/pages/HomePage.vue";
import ScopesPage from "@/pages/ScopesPage.vue";
import ScopesIndexPage from "@/pages/ScopesIndexPage.vue";
import ScopesSinglePage from "@/pages/ScopesSinglePage.vue";
import OrganizationsPage from "@/pages/OrganizationsPage.vue";
import OrganizationsIndexPage from "@/pages/OrganizationsIndexPage.vue";
import ChannelsPage from "@/pages/ChannelsPage.vue";
import ChannelsIndexPage from "@/pages/ChannelsIndexPage.vue";
import IntentsPage from "@/pages/IntentsPage.vue";
import IntentsIndexPage from "@/pages/IntentsIndexPage.vue";
import IntentsSinglePage from "@/pages/IntentsSinglePage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import ForgotPasswordPage from "@/pages/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/pages/ResetPasswordPage.vue";
// Importing stores
import useScopeStore from "@/stores/scope";
import useIntentStore from "@/stores/intent";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_ROUTER_BASE_URL),
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      component: ForgotPasswordPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPasswordPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
    {
      path: "/scopes",
      name: "scopes",
      component: ScopesPage,
      children: [
        {
          path: "",
          name: "scopesIndex",
          component: ScopesIndexPage,
          meta: {
            title: "Scopes",
          },
        },
        {
          path: ":id",
          name: "scopesSinglePage",
          component: ScopesSinglePage,
          meta: {
            title(route) {
              return useScopeStore().getScopePrefixById(route.params.id);
            },
          },
        },
      ],
    },
    {
      path: "/organizations",
      name: "organizations",
      component: OrganizationsPage,
      children: [
        {
          path: "",
          name: "organizationsIndex",
          component: OrganizationsIndexPage,
        },
      ],
    },
    {
      path: "/channels",
      name: "channels",
      component: ChannelsPage,
      children: [
        {
          path: "",
          name: "channelsIndex",
          component: ChannelsIndexPage,
        },
      ],
    },
    {
      path: "/intents",
      name: "intents",
      component: IntentsPage,
      children: [
        {
          path: "",
          name: "intentsIndex",
          component: IntentsIndexPage,
          meta: {
            title: "Intents",
          },
        },
        {
          path: ":id",
          name: "intentsSinglePage",
          component: IntentsSinglePage,
          meta: {
            title(route) {
              return useIntentStore().getCurrentIntentName;
            },
          },
        },
      ],
    },
  ],
});

router.beforeEach(authMiddleware);

export default router;
