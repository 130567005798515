<template>
  <section class="space-y-4">
    <div class="item-center flex justify-between">
      <h2 class="text-base uppercase text-white/75">
        {{ $t("context") }}
      </h2>
      <BaseButton :outline="true" size="xs" @click="editContext">{{
        $t("edit")
      }}</BaseButton>
    </div>
    <p>{{ context }}</p>
  </section>
</template>

<script setup>
import useModalStore from "@/stores/modal";
import { useI18n } from "vue-i18n";
import IntentContextForm from "./IntentContextForm.vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  context: {
    type: String,
    default: "",
  },
});

const { openModal } = useModalStore();
const { t } = useI18n();

/**
 * open edit context modal
 */
function editContext() {
  openModal({
    title: t("editContext"),
    component: IntentContextForm,
    props: {
      intentID: props.id,
      context: props.context,
    },
  });
}
</script>
