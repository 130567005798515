<template>
  <GridContainer>
    <RouterView />
  </GridContainer>
</template>

<script setup>
import { RouterView } from "vue-router";
import useChannelTypeStore from "@/stores/channelType";
import useChannelStore from "@/stores/channel";

const { fetchChannelTypeList } = useChannelTypeStore();
const { fetchChannelList } = useChannelStore();

await fetchChannelTypeList();
await fetchChannelList();
</script>
