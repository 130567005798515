import { defineStore } from "pinia";
import { ScopeService } from "@/api/services";
import { DynamicIntentModuleService } from "@/api/services";

export default defineStore("scopeStore", {
  id: "scope",
  state: () => ({
    scopeList: [],
    currentScopeID: null,
  }),
  actions: {
    /**
     * Fetch the list of scopes
     */
    async fetchScopeList() {
      const { scopeList } = await ScopeService.readAll();

      this.scopeList = scopeList;
    },
    /**
     * Fetch a scope by id
     * @param {Number} id
     */
    async fetchScope(id) {
      const { scope } = await ScopeService.read(id);
      this.scopeList = this.scopeList.map((elm) =>
        elm.id === scope.id ? scope : elm,
      );
      this.currentScopeID = scope.id;
    },
    /**
     * Add a new scope
     * @param {Object} payload
     */
    async addScope(payload) {
      // TODO: remove this line
      payload.organizationId = 1;
      const {
        scope: { id },
      } = await ScopeService.create(payload);
      const { scope } = await ScopeService.read(id);
      this.scopeList.push(scope);
    },
    /**
     * Update a scope
     * @param {Number} id
     * @param {Object} payload
     */
    async updateScope(id, payload) {
      const { scope } = await ScopeService.update(id, payload);
      this.scopeList = this.scopeList.map((elm) =>
        elm.id === scope.id ? scope : elm,
      );
    },
    /**
     * Delete a scope
     * @param {Number} id
     */
    async deleteScope(id) {
      await ScopeService.delete(id);
      this.scopeList = this.scopeList.filter((elm) => elm.id !== id);
    },
    /**
     * Toggle debug mode of a scope
     * @param {Number} id
     */
    async toggleDebug(id) {
      const { scope } = await ScopeService.toggleDebug(Number(id));
      this.scopeList.find((elm) => elm.id === Number(id)).debug_mode =
        scope.debug_mode;
    },
    /**
     * Add a new DynamicIntentModule to a scope
     * @param {Number} scopeID
     * @param {Object} payload
     */
    async addDimToScope(scopeID, payload) {
      const { dim } = await DynamicIntentModuleService.create(payload);
      this.scopeList.find((elm) => elm.id === scopeID).dims.push(dim);
    },
    /**
     * Delete a DynamicIntentModule from a scope
     * @param {Number} scopeID
     * @param {Number} dimID
     */
    async deleteDimFromScope(scopeID, dimID) {
      await DynamicIntentModuleService.delete(dimID);
      this.scopeList.find((elm) => elm.id === scopeID).dims = this.scopeList
        .find((elm) => elm.id === scopeID)
        .dims.filter((elm) => elm.id !== dimID);
    },
    /**
     * Update toggle debug DynamicIntentModule from a scope
     * @param {Number} scopeID
     * @param {Number} dimID
     * @param {Object} payload
     */
    async toggleDebugDimForScope(scopeID, dimID) {
      const { dim } = await DynamicIntentModuleService.toggleDebug(dimID);
      this.scopeList
        .find((elm) => elm.id === scopeID)
        .dims.find((elm) => elm.id === dimID).debug_mode = dim.debug_mode;
    },
  },
  getters: {
    /**
     * Get a scope prefix by id
     * @param {Number} id
     */
    getScopePrefixById: (state) => (id) => {
      return state.scopeList.find((elm) => elm.id === parseInt(id))?.prefix;
    },
    /**
     * Get a scope by id
     * @param {Number} id
     */
    getCurrentScope: (state) => {
      return state.scopeList.find((elm) => elm.id === state.currentScopeID);
    },
    /**
     * Get scope list as options
     * @param {Object} state
     */
    getScopeListAsOptions: (state) => {
      return state.scopeList.map((elm) => {
        return {
          value: elm.id,
          label: `${elm.prefix}/${elm.slug}`,
        };
      });
    },
  },
});
