import * as yup from "yup";

/**
 * @description - Validation schema for intent form
 */
export default yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  context: yup.string().required(),
  scopeId: yup.number().required(),
  language: yup.string().required(),
  description: yup.string(),
  expressions: yup.string().required(),
  responses: yup.string().required(),
  debug_mode: yup.boolean(),
});

export const editContextSchema = yup.object({
  context: yup.string().required(),
});

export const createExpressionSchema = yup.object({
  expression: yup.string().required(),
});
export const editExpressionSchema = yup.object({
  newExpression: yup.string().required(),
});

export const createResponseSchema = yup.object({
  response: yup.string().required(),
});
export const editResponseSchema = yup.object({
  newResponse: yup.string().required(),
});

export const editPermissionsSchema = yup.object({
  slugArray: yup.string().matches(/^[a-zA-Z0-9 ,]+$/g, "Invalid slug array"),
});
