<template>
  <div class="space-y-1">
    <label v-if="props.label" :for="$attrs.id"
      >{{ label }}
      <span v-if="'required' in $attrs && label" class="text-danger"
        >*</span
      ></label
    >
    <slot
      class="w-full"
      :value="value"
      v-bind="$attrs"
      v-on="validationListeners"
    />
    <small
      v-if="errorMessage"
      class="block font-medium text-danger first-letter:capitalize"
      >{{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useField } from "vee-validate";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  label: { type: String },
  name: { type: String, required: true },
  modelValue: {},
  initialValue: {},
});

const { value, errorMessage, handleChange, handleBlur, validate } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    syncVModel: true,
    initialValue: props.initialValue || props.modelValue,
  },
);

// Validate on mounted
onMounted(() => {
  if (value.value) validate();
});

// Validation listeners
const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>
