<template>
  <Suspense>
    <div class="flex h-screen flex-col">
      <AppHeader class="z-10" ref="header" :showBackground="!isChatOpen" />
      <AppMain class="grow" :style="`padding-top: calc(${headerHeight}px + 2rem)`" />
      <Transition name="fade">
        <AppNav v-if="isChatOpen" class="fixed bottom-20 left-6 z-10" />
      </Transition>
      <BaseChatLogo
        tag="button"
        :showLabel="!isChatOpen"
        class="fixed bottom-6 left-6 z-10 h-10"
        @click="isChatOpen = !isChatOpen"
      />
      <Transition name="fade">
        <AppChat v-if="isChatOpen" @on-chat-close="isChatOpen = false" />
      </Transition>
    </div>
  </Suspense>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";

import AppHeader from "@/components/app/AppHeader.vue";
import AppMain from "@/components/app/AppMain.vue";
import AppChat from "@/components/app/AppChat.vue";
import AppNav from "@/components/app/AppNav.vue";

import useModalStore from "@/stores/modal";

const isChatOpen = ref(false);
const route = useRoute();

const header = ref();
const headerHeight = ref(0);

const modal = storeToRefs(useModalStore());
const isModalOpen = modal.isOpen;

useHead({
  bodyAttrs: {
    class: {
      "overflow-hidden": () =>
        route.name === "home" || isChatOpen.value || isModalOpen.value,
    },
  },
});

onMounted(() => {
  headerHeight.value = header.value.header.clientHeight;
});

watch(
  () => route.name,
  () => {
    setTimeout(() => {
      isChatOpen.value = false;
    }, 300);
  }
);
</script>
