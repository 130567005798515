<template>
  <div :class="twMerge(`grid gap-4`, gridCols[cols], $attrs.class)">
    <slot />
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  cols: {
    type: Number,
    default: 12,
  },
});

/**
 * Object containing all the possible grid-cols-* classes
 */
const gridCols = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
  5: "grid-cols-5",
  6: "grid-cols-6",
  7: "grid-cols-7",
  8: "grid-cols-8",
  9: "grid-cols-9",
  10: "grid-cols-10",
  11: "grid-cols-11",
  12: "grid-cols-12",
};
</script>
