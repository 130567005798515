<template>
  <div
    ref="chatMessagesList"
    class="chat-messages-list"
    :class="{
      'fade-out': showTopFade && showBottomFade,
      'fade-out-top': showTopFade && !showBottomFade,
      'fade-out-bottom': showBottomFade && !showTopFade,
    }"
    @scroll="checkIfShowFadeOut"
  >
    <ChatMessage
      v-for="chat in chats"
      :key="chat.id"
      v-bind="chat"
      :class="chat.user ? 'ml-auto' : 'mr-auto'"
    />
  </div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted } from "vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";

const props = defineProps({
  chats: {
    type: Array,
    default: () => [],
  },
  showFade: {
    type: Boolean,
    default: true,
  },
});

const chatMessagesList = ref(null);
onMounted(scrollToBottom);

const showTopFade = ref(false);
const showBottomFade = ref(false);

function checkIfShowFadeOut() {
  if (!props.showFade) return;

  const elm = chatMessagesList.value;

  const scrollTop = elm.scrollTop;
  const scrollHeight = elm.scrollHeight;
  const clientHeight = elm.clientHeight;

  if (clientHeight >= scrollHeight) return;

  const offset = 0;

  showBottomFade.value = !(scrollTop >= scrollHeight - clientHeight - offset);
  showTopFade.value = !(scrollTop <= offset);
}

watch(
  () => props.chats,
  () => scrollToBottom("smooth"),
  { deep: true }
);

/**
 * function to scroll to bottom of the chat
 * @param {string} behavior
 */
async function scrollToBottom(behavior = "auto") {
  await nextTick();
  const elm = chatMessagesList.value;
  elm.scrollTo({
    top: elm.scrollHeight - elm.clientHeight,
    behavior,
  });
}
</script>

<style lang="postcss" scoped>
.chat-messages-list {
  @apply flex flex-col gap-y-6 overflow-y-auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &.fade-out {
    mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      black 48px,
      black calc(100% - 48px),
      transparent 100%
    );
  }

  &.fade-out-bottom {
    mask-image: linear-gradient(to bottom, black calc(100% - 48px), transparent 100%);
  }

  &.fade-out-top {
    mask-image: linear-gradient(to top, black calc(100% - 48px), transparent 100%);
  }
}
</style>
