<template>
  <div class="relative" v-click-outside="() => (isMenuVisible = false)">
    <button
      class="flex cursor-pointer items-center gap-x-3"
      @click="isMenuVisible = !isMenuVisible"
    >
      <BaseAvatar :initials="`${user?.name[0]}${user?.surname[0]}`" />
      <p class="font-bold">{{ user?.name }} {{ user?.surname }}</p>
      <BaseIcon
        name="expand_more"
        class="transition-transform"
        :class="{
          'rotate-180': isMenuVisible,
        }"
      />
    </button>
    <BaseDropdownMenu v-show="isMenuVisible" class="absolute right-2 mt-2">
      <BaseMenuItem tag="RouterLink" to="#">{{
        $t("userProfileMenu.profile")
      }}</BaseMenuItem>
      <BaseMenuItem tag="RouterLink" to="#">
        {{ $t("userProfileMenu.settings") }}</BaseMenuItem
      >
      <BaseMenuItem
        tag="button"
        @click="logout"
        class="text-left text-danger"
        >{{ $t("userProfileMenu.logout") }}</BaseMenuItem
      >
    </BaseDropdownMenu>
  </div>
</template>

<script setup>
import { ref } from "vue";
import useAuthStore from "@/stores/auth";
import { storeToRefs } from "pinia";

const isMenuVisible = ref(false);
const { logout } = useAuthStore();
const { user } = storeToRefs(useAuthStore());
</script>
