<template>
  <div class="flex gap-x-2">
    <div class="font-medium">*********</div>
    <div
      class="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-primary/10 text-primary"
    >
      <BaseIcon
        @click="handleCopyToken"
        @mouseleave="copied = false"
        class="text-sm"
        name="content_copy"
        :data-tooltip="copied ? $t('copied') : $t('copy')"
        data-tooltip-placement="right"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
});

const copied = ref(false);

/**
 * Copy token to clipboard
 */
function handleCopyToken() {
  navigator.clipboard.writeText(props.token);
  copied.value = true;
}
</script>
