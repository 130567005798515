<template>
  <Teleport to="body">
    <div
      class="fixed bottom-5 left-1/2 z-50 flex -translate-x-1/2 flex-col gap-y-3"
      v-if="toastStore.toastList.length > 0"
    >
      <TransitionGroup name="fade">
        <BaseToast
          v-for="toast in toastStore.toastList"
          :key="toast.id"
          :variant="toast.variant"
          @on-closing="toastStore.removeToast(toast.id)"
        >
          {{ toast.content }}
        </BaseToast>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup>
import useToastStore from "@/stores/toast";

const toastStore = useToastStore();
</script>
