import BaseService from "./BaseService";

/**
 * Service class for interacting with the 'organization' resource in the API.
 * Extends the BaseService class.
 */
class OrganizationService extends BaseService {
  /**
   * The resource endpoint for the 'organization' service.
   * @type {string}
   */
  resource = "organization";

  /**
   * Fetches all items from the specified resource.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async readAll() {
    const { organizationList: organizations } = await this.api.get(
      `/${this.resource}`,
    );

    const organizationList = await Promise.all(
      organizations.map(async (org) => {
        const relatedData = await this.relatedData(org.id);
        return { ...org, ...relatedData };
      }),
    );

    return { organizationList };
  }

  /**
   * Fetches a single item from the specified resource by its ID.
   * @param {string} id - The ID of the item to be fetched.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async read(id) {
    const { organization: organizationData } = await this.api.get(
      `/${this.resource}/${id}`,
    );
    const relatedData = await this.relatedData(id);

    const organization = { ...organizationData, ...relatedData };
    return { organization };
  }

  /**
   * Fetches the related data for the specified organization.
   * @param {string} id - The ID of the organization.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async relatedData(id) {
    const { scopebase } = await this.readScopeBase(id);
    const { scopes: debugScopes } = await this.readDebugScopes(id);
    const { scopes: activeScopes } = await this.readActiveScopes(id);

    return { scopebase, debugScopes, activeScopes };
  }

  /**
   * Fetches the scope base for the specified organization.
   * @param {string} id - The ID of the organization.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async readScopeBase(id) {
    return this.api.get(`/${this.resource}/${id}/scopebase`);
  }
  /**
   * Fetches the debug scopes for the specified organization.
   * @param {string} id - The ID of the organization.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async readDebugScopes(id) {
    return this.api.get(`/${this.resource}/${id}/scopes/debug`);
  }
  /**
   * Fetches the active scopes for the specified organization.
   * @param {string} id - The ID of the organization.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async readActiveScopes(id) {
    return this.api.get(`/${this.resource}/${id}/scopes/active`);
  }
}

const organizationServiceInstance = new OrganizationService();

export default organizationServiceInstance;
