<template>
  <Component :is="currentLayout" v-if="$route" />
  <AppModal />
  <AppToasts />
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";

import AppModal from "@/components/app/AppModal.vue";
import AppToasts from "@/components/app/AppToasts.vue";

import DefaultLayout from "@/layouts/defaultLayout.vue";
import AuthLayout from "@/layouts/authLayout.vue";
const layouts = new Map([
  ["defaultLayout", DefaultLayout],
  ["authLayout", AuthLayout],
]);

const route = useRoute();
let currentLayout = computed(() =>
  layouts.get(`${route.meta.layout || "default"}Layout`),
);
</script>
