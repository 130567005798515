<template>
  <BaseBoxColor variant="gray" class="w-full p-4">
    <GridRow>
      <GridCol :span-md="7" class="space-y-4">
        <div class="flex items-center gap-x-4">
          <h3>{{ name }}</h3>
          <BaseBadge
            :variant="enabled ? 'success' : 'danger'"
            class="bg-opacity-70"
            >{{ enabled ? $t("online") : $t("offline") }}</BaseBadge
          >
        </div>
        <ul class="space-y-2">
          <li>
            <h4 class="text-sm uppercase text-white/75">{{ $t("type") }}</h4>
            <strong> {{ type }}</strong>
          </li>
          <li>
            <h4 class="text-sm uppercase text-white/75">{{ $t("token") }}</h4>
            <BaseToken :token="token" />
          </li>
          <li v-if="cidr">
            <h4 class="text-sm uppercase text-white/75">{{ $t("cidr") }}</h4>
            <strong> {{ cidr }}</strong>
          </li>
        </ul>
        <div class="flex justify-between">
          <h4 class="text-sm uppercase text-white/75">{{ $t("usage") }}</h4>
          <InputSwitch
            :label="$t('enabled')"
            :initial-value="props.enabled"
            name="enabled"
            @change="toggleEnabled(id)"
          />
        </div>
        <div>
          <BaseChart :data="latestUsage" :label="$t('usage')" />
        </div>
      </GridCol>
      <GridCol :span-md="5">
        <div class="mb-2">
          <h6 class="text-end text-white/75">#{{ code }}</h6>
        </div>
        <div class="mb-2 flex items-center">
          <h4 class="text-sm uppercase text-white/75">{{ $t("scopeList") }}</h4>
          <BaseButton
            variant="primary"
            :outline="true"
            size="xs"
            class="ml-auto"
            @click="isScopeListEditing = !isScopeListEditing"
          >
            {{ !isScopeListEditing ? $t("manage") : $t("close") }}
          </BaseButton>
        </div>
        <div v-if="Scopes.length === 0 && !isScopeListEditing">
          {{ $t("noScopes") }}
        </div>
        <ul v-if="!isScopeListEditing" class="space-y-1">
          <li v-for="scope in Scopes" :key="scope.id" class="flex gap-x-2">
            {{ scope.prefix }}/{{ scope.slug }}
          </li>
        </ul>
        <ScopeListForm
          v-else
          :current-scopes="Scopes"
          :id="id"
          :name="name"
          :type_id="type_id"
        />
      </GridCol>
    </GridRow>
  </BaseBoxColor>
</template>

<script setup>
import { ref } from "vue";
import ScopeListForm from "./ScopeListForm.vue";
import useChannelStore from "@/stores/channel";

const { toggleEnabled } = useChannelStore();

const props = defineProps({
  id: {
    type: [String, Number],
    required: true,
  },
  code: {
    type: String,
    default: "code",
  },
  name: {
    type: String,
    required: true,
  },
  type_id: {
    type: [String, Number],
  },
  type: {
    type: String,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
  enabled: {
    type: Boolean,
    default: false,
  },
  Scopes: {
    type: Array,
    default: () => [],
  },
  cidr: {
    type: [String, null],
    required: true,
  },
  latestUsage: {
    type: Array,
    default: () => [],
  },
});

const isScopeListEditing = ref(false);
</script>
