import { defineStore } from "pinia";
import { ChannelTypeService } from "@/api/services";

export default defineStore("channelTypeStore", {
  id: "channelType",
  state: () => ({
    channelTypeList: [],
    channelType: null,
  }),
  actions: {
    /**
     * Fetch the list of channel types
     */
    async fetchChannelTypeList() {
      const { channelTypeList } = await ChannelTypeService.readAll();
      this.channelTypeList = channelTypeList;
    },
    /**
     * Add a new channel type
     * @param {Object} payload
     */
    async addChannelType(payload) {
      const { channelType } = await ChannelTypeService.create(payload);
      this.channelTypeList.unshift(channelType);
    },
    /**
     * Update a channel type
     * @param {Number} id
     * @param {Object} payload
     */
    async updateChannelType(id, payload) {
      const { channelType } = await ChannelTypeService.update(id, payload);
      this.channelTypeList = this.channelTypeList.map((elm) =>
        elm.id === channelType.id ? channel : elm,
      );
    },
    /**
     * Delete a channel type
     * @param {Number} id
     */
    async deleteChannelType(id) {
      await ChannelTypeService.delete(id);
      this.channelTypeList = this.channelTypeList.filter(
        (elm) => elm.id !== id,
      );
    },
  },
  getters: {
    /**
     * Get the list of channel types has options
     */
    getChannelTypeAsOptions: (state) =>
      state.channelTypeList.map((channelType) => ({
        label: channelType.name,
        value: channelType.id,
      })) || [],
    /**
     * Get a channel type by id
     * @param {Number} id
     * @returns {Object}
     */
    getChannelTypeById: (state) => (id) =>
      state.channelTypeList.find((channelType) => channelType.id === id),
  },
});
