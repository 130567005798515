<template>
  <section class="space-y-4">
    <div class="item-center flex justify-between">
      <h2 class="text-base uppercase text-white/75">
        {{ $t("permissions") }}
      </h2>
      <BaseButton :outline="true" size="xs" @click="editPermissions">{{
        $t("edit")
      }}</BaseButton>
    </div>
    <div v-if="permissions.length" class="flex flex-wrap gap-2">
      <BaseBadge
        v-for="permission in permissions"
        variant="gray"
        :key="permission.slug"
        ><small>{{ permission.name }}</small></BaseBadge
      >
    </div>
  </section>
</template>

<script setup>
import useModalStore from "@/stores/modal";
import { useI18n } from "vue-i18n";
import IntentPermissionsForm from "./IntentPermissionsForm.vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  permissions: {
    type: Array,
    default: () => [],
  },
});

const { openModal } = useModalStore();
const { t } = useI18n();

/**
 * open edit permissions modal
 */
function editPermissions() {
  openModal({
    title: t("editPermissions"),
    component: IntentPermissionsForm,
    props: {
      intentID: props.id,
      permissions: props.permissions,
    },
  });
}
</script>
