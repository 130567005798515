<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :class="
      twMerge(
        `inline-block rounded-2xl px-2 py-1 font-medium leading-5`,
        variants[variant],
        $attrs.class,
      )
    "
    ><slot />
  </component>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  tag: {
    type: String,
    default: "span",
    validator: (value) => ["span", "a", "RouterLink"].includes(value),
  },
  variant: {
    type: String,
    default: "primary",
    validator: (value) =>
      ["primary", "success", "danger", "warning", "gray", "lightGray"].includes(
        value,
      ),
  },
});

/**
 * Object containing class names for different variants of the BaseBadge component.
 */
const variants = {
  primary: "bg-primary-300",
  success: "bg-success",
  danger: "bg-danger",
  warning: "bg-warning",
  gray: "bg-darkGray-200",
  lightGray: "bg-darkGray-100 text-darkGray-200",
};
</script>
