<template>
  <FormBuilder
    class="space-y-4"
    :validation-schema="validationSchema"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <InputText
      id="newResponse"
      name="newResponse"
      :initial-value="response"
      :label="$t('response')"
      required
    />
    <div class="text-end">
      <BaseButton type="submit" :disabled="isSubmitting">{{
        $t("save")
      }}</BaseButton>
    </div>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useIntentStore from "@/stores/intent";
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import { editResponseSchema as validationSchema } from "./validationSchemas.js";

const props = defineProps({
  intentID: {
    type: Number,
    required: true,
  },
  response: {
    type: String,
    required: true,
  },
  responseID: {
    type: Number,
    required: true,
  },
  is_rounded: {
    type: Boolean,
    required: false,
  },
});

const { updateResponse } = useIntentStore();
const { addToast } = useToastStore();
const { closeModal } = useModalStore();
const error = ref(null);
const { t } = useI18n();

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values) {
  try {
    await updateResponse(props.intentID, props.responseID, values);
    addToast({
      content: t("itemUpdated"),
      variant: "success",
    });
    closeModal();
  } catch (err) {
    error.value = err;
  }
}
</script>
