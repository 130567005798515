<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :class="
      twMerge(
        `inline-flex items-center gap-x-2 rounded-lg border font-bold transition-opacity hover:opacity-90 disabled:cursor-not-allowed disabled:border-darkGray-100 disabled:bg-darkGray-100 disabled:text-darkGray`,
        variants[variant],
        sizes[size],
        outline && `bg-transparent`,
        circle && `aspect-square rounded-full`,
        $attrs.class,
      )
    "
    ><slot
  /></component>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed } from "vue";

import { twMerge } from "tailwind-merge";

const props = defineProps({
  tag: {
    type: String,
    default: "button",
    validator: (value) => ["button", "a", "RouterLink"].includes(value),
  },
  variant: {
    type: String,
    default: "primary",
    validator: (value) => ["primary", "danger", "gray"].includes(value),
  },
  size: {
    type: String,
    default: "md",
    validator: (value) => ["xs", "sm", "md"].includes(value),
  },
  outline: {
    type: Boolean,
    default: false,
  },
  circle: {
    type: Boolean,
    default: false,
  },
});

/**
 * Object containing class names for different variants of the BaseButton component.
 */
const variants = computed(() => ({
  primary: `bg-primary text-darkGray-400 border-primary ${
    props.outline && "text-primary"
  }`,
  danger: `bg-danger border-danger ${props.outline && "text-danger"}`,
  gray: `bg-darkGray-100 border-darkGray-100 ${
    props.outline && "text-darkGray-100"
  }`,
}));

/**
 * Object containing class names for different sizes of the BaseButton component.
 */
const sizes = computed(() => ({
  xs: "p-2 text-sm leading-none",
  sm: "px-4 py-2 text-sm leading-tight",
  md: "px-4 py-2 text-md leading-tight",
}));
</script>
