<template>
  <nav>
    <ul class="flex gap-x-2 text-4xl font-medium text-white/50">
      <li v-for="(link, index) in breadcrumbs">
        <template v-if="breadcrumbs.length - 1 !== index">
          <RouterLink
            class="underline underline-offset-4"
            :to="{ name: link.name }"
          >
            {{ link.title }}
          </RouterLink>
          >
        </template>
        <template v-else>
          <h1 class="text-white">{{ link.title }}</h1>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { RouterLink, useRoute } from "vue-router";

const route = useRoute();

const breadcrumbs = computed(() => {
  return route.matched[0].children.map((r) => ({
    title:
      typeof r.meta.title === "function"
        ? r.meta.title.call(this, route)
        : r.meta.title,
    name: r.name,
  }));
});
</script>
