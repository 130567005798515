import * as yup from "yup";

/**
 * @description - Validation schema for login form
 */
export const loginSchema = yup.object({
  userEmail: yup.string().required(),
  userPassword: yup.string().required(),
});

/**
 * @description - Validation schema for forgot password form
 */
export const forgotPasswordSchema = yup.object({
  email: yup.string().required(),
});

/**
 * @description - Validation schema for reset password form
 */
export const resetPasswordSchema = yup.object({
  newPassword: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});
