<template>
  <section class="space-y-4">
    <div class="item-center flex justify-between">
      <h2 class="text-base uppercase text-white/75">
        {{ $t("expressions") }}
      </h2>
      <BaseButton :outline="true" size="xs" @click="addExpression">{{
        $t("add")
      }}</BaseButton>
    </div>
    <BaseBoxColor
      class="block"
      variant="gray"
      v-for="expression in expressions"
    >
      <div class="flex justify-between">
        <p>
          {{ expression.text }}
        </p>
        <div class="flex gap-x-2">
          <div
            class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-primary/20 text-primary"
            @click="editExpression(expression.id)"
          >
            <BaseIcon class="text-base" name="edit" />
          </div>
          <div
            class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-danger/20 text-danger"
            @click="handleDeleteExpression(id, expression.id)"
          >
            <BaseIcon class="text-base" name="delete" />
          </div>
        </div>
      </div>
    </BaseBoxColor>
  </section>
</template>

<script setup>
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import { useI18n } from "vue-i18n";
import useIntentStore from "@/stores/intent";
import IntentExpressionCreateForm from "./IntentExpressionCreateForm.vue";
import IntentExpressionEditForm from "./IntentExpressionEditForm.vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  expressions: {
    type: Array,
    default: () => [],
  },
});

const { addToast } = useToastStore();
const { openModal, openConfirmModal } = useModalStore();
const { deleteExpression } = useIntentStore();
const { t } = useI18n();

/**
 * open add expression modal
 */
function addExpression() {
  openModal({
    title: t("addExpression"),
    component: IntentExpressionCreateForm,
    props: {
      intentID: props.id,
    },
  });
}

/**
 * open edit expression modal
 * @param {Number} id
 */
function editExpression(id) {
  openModal({
    title: t("editExpression"),
    component: IntentExpressionEditForm,
    props: {
      intentID: props.id,
      expression: props.expressions.find((e) => e.id === id).text,
      expressionID: id,
    },
  });
}

/**
 * delete expression
 * @param {Number} id
 */
async function handleDeleteExpression(intentID, expressionID) {
  const response = await openConfirmModal({
    title: t("deleteExpression"),
  });

  if (!response) return;

  // delete expression
  try {
    await deleteExpression(intentID, expressionID);
    addToast({
      variant: "success",
      content: t("itemDeleted"),
    });
  } catch (error) {
    addToast({
      variant: "danger",
      content: t("deleteErrorMessage"),
    });
  }
}
</script>
