<template>
  <ul class="space-y-1">
    <li v-for="scope in currentScopes" :key="scope.id" class="flex gap-x-2">
      {{ scope.prefix }}/{{ scope.slug }}
      <div
        v-if="currentScopes.length > 1"
        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-danger/20 text-danger"
        @click="deleteChannelScope(scope.id)"
      >
        <BaseIcon class="text-base" name="delete" />
      </div>
    </li>
  </ul>

  <form class="mt-4 flex items-start gap-x-2" @submit.prevent="onSubmit">
    <div class="grow">
      <InputText
        list="scopeList"
        name="scopeList"
        class="w-full"
        v-model="formData"
        :placeholder="$t('scopeName')"
      />
    </div>
    <datalist id="scopeList">
      <option v-for="scope in options" :key="scope.id" :value="scope.slug">
        {{ scope.prefix }}/{{ scope.slug }}
      </option>
    </datalist>
    <BaseButton
      class="h-[2.6rem]"
      type="submit"
      size="xs"
      :disabled="!formData"
    >
      {{ $t("addNew") }}
    </BaseButton>
  </form>
</template>

<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import useScopeStore from "@/stores/scope";
import useChannelStore from "@/stores/channel";
import useModalStore from "@/stores/modal";
import { useI18n } from "vue-i18n";

const props = defineProps({
  id: {
    type: [String, Number],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  type_id: {
    type: [String, Number],
    required: true,
  },
  currentScopes: {
    type: Array,
    default: () => [],
  },
});

const { updateChannel } = useChannelStore();
const { fetchScopeList } = useScopeStore();
const { scopeList } = storeToRefs(useScopeStore());
const { t } = useI18n();
await fetchScopeList();

/**
 * datalist options exluding current scopes
 */
const options = computed(() => {
  return scopeList.value.filter(
    (scope) =>
      props.currentScopes.findIndex(
        (currentScope) => currentScope.id === scope.id,
      ) === -1,
  );
});

const formData = ref(null);

/**
 * handle form submit
 */
async function onSubmit() {
  const newScopeId = scopeList.value.find(
    (scope) => scope.slug === formData.value,
  ).id;

  await updateScopeList([
    ...props.currentScopes.map((scope) => scope.id),
    newScopeId,
  ]);

  formData.value = null;
}

const modal = useModalStore();
/**
 * Delete channel scope
 * @param {Number} scopeId
 */
async function deleteChannelScope(scopeId) {
  const respose = await modal.openConfirmModal({
    title: t("deleteScope"),
  });

  if (!respose) return;

  await updateScopeList(
    props.currentScopes
      .filter((scope) => scope.id !== scopeId)
      .map((scope) => scope.id),
  );
}

/**
 * Update channel scope list
 * @param {Array} scopeList
 */
async function updateScopeList(scopeList) {
  const payload = {
    scopes: scopeList,
    name: props.name,
    typeId: props.type_id,
  };

  await updateChannel(props.id, payload);
}
</script>
