<template>
  <div class="animate-opacity flex items-start gap-x-2">
    <BaseChatLogo />
    <p>{{ $t("chat.typing") }}</p>
  </div>
</template>

<script setup></script>

<style scoped>
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.animate-opacity {
  animation: fadeInOut 1.2s infinite;
}
</style>
