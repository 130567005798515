<template>
  <FormBuilder
    class="space-y-4"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <div class="flex items-center gap-x-2">
      <InputText
        id="prefix"
        name="prefix"
        :label="$t('prefix')"
        :placeholder="$t('chooseAPrefix')"
        required
      />
      <div class="relative -bottom-4 text-5xl text-white/30">/</div>
      <InputText id="slug" name="slug" label="Scope slug" required />
    </div>
    <TextareaField
      id="description"
      name="description"
      :label="$t('description')"
      :rows="5"
    />
    <InputSwitch id="debug_mode" name="debug_mode" :label="$t('debugMode')" />
    <div class="text-end">
      <BaseButton type="submit" :disabled="isSubmitting">{{
        $t("startCreate")
      }}</BaseButton>
    </div>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useScopeStore from "@/stores/scope";
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import validationSchema from "./validationSchemas.js";

const { addScope } = useScopeStore();
const { addToast } = useToastStore();
const { closeModal } = useModalStore();
const error = ref(null);
const { t } = useI18n();

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values) {
  try {
    await addScope(values);
    addToast({
      content: t("itemCreated"),
      variant: "success",
    });
    closeModal();
  } catch (err) {
    error.value = err;
  }
}
</script>
