<template>
  <div
    class="bg-darkGray-400 rounded-xl border border-white/10 p-4"
    aria-modal="true"
    role="dialog"
  >
    <div class="mb-4 flex items-start">
      <slot name="header" />
      <BaseCloseButton class="ml-auto" @click="emit('onClosing')" />
    </div>
    <slot />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";

const emit = defineEmits(["onClosing"]);

/**
 * Close modal on ESC key press.
 * @param {Object} event
 */
function keydownListener(event) {
  if (event.key === "Escape") emit("onClosing");
}

onMounted(() => {
  document.addEventListener("keydown", keydownListener);
});

onUnmounted(() => {
  document.removeEventListener("keydown", keydownListener);
});
</script>
