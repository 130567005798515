<template>
  <GridContainer size="sm">
    <h3 class="text-center font-code">{{ $t("lory.hey") }}</h3>
    <div
      class="mt-10 flex flex-wrap items-start justify-around gap-x-6 gap-y-10"
    >
      <BaseBoxColor
        v-for="(phrase, index) in phrases"
        :data-aos="fadeEffects[getRandomInt(width.length - 1)]"
        :data-aos-delay="50 * index"
        :variant="colors[getRandomInt(colors.length - 1)]"
        class="relative font-code font-medium"
        :class="[
          positions[getRandomInt(positions.length - 1)],
          index % 3 === 0 && width[getRandomInt(width.length - 1)],
        ]"
        >{{ phrase }}</BaseBoxColor
      >
    </div>
  </GridContainer>
</template>

<script setup>
import { onMounted } from "vue";
import AOS from "aos";
import { getRandomInt } from "@/helpers/math";
import { phrases, positions, colors, width, fadeEffects } from "./previewData";

onMounted(() => {
  AOS.init();
});
</script>
