<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="modal.isOpen" class="overlay z-20">
        <div
          @click="modal.closeModal"
          class="fixed left-0 top-0 z-30 h-full w-full"
        ></div>
        <div
          class="fixed left-1/2 top-1/2 z-40 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center p-4"
        >
          <BaseModal class="min-w-[32rem]" @onClosing="modal.closeModal">
            <template v-if="modal.title" #header>
              <h3>{{ modal.title }}</h3>
            </template>
            <component
              :is="modal.component"
              v-bind="modal.props"
              @close-modal="modal.closeModal()"
            />
          </BaseModal>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import useModalStore from "@/stores/modal";

const modal = useModalStore();
</script>
