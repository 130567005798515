<template>
  <section class="mb-8 flex items-center justify-between">
    <div class="flex items-center gap-x-8">
      <h1>{{ $t("organization") }}</h1>
      <BaseSearch @on-searching="setFilterText" />
    </div>
    <BaseButton @click="handleNewOrganization">{{ $t("addNew") }}</BaseButton>
  </section>
  <BaseDataList :tot-data="filteredData.length" :is-searching="!!filterText">
    <GridRow class="gap-6">
      <GridCol :span-md="6" v-for="organization in filteredData">
        <OrganizationCard v-bind="organization" :key="organization.id" />
      </GridCol>
    </GridRow>
  </BaseDataList>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useModalStore from "@/stores/modal";
import useOrganizationStore from "@/stores/organization";
import OrganizationCard from "@/components/organizations/OrganizationCard.vue";
import OrganizationForm from "@/components/organizations/OrganizationForm.vue";
import useFilterData from "@/composables/useFilterData";

const { fetchOrganizationList } = useOrganizationStore();
await fetchOrganizationList();

const modal = useModalStore();
const { t } = useI18n();

/**
 * Handle new organization event.
 */
function handleNewOrganization() {
  modal.openModal({
    title: t("newOrganization"),
    component: OrganizationForm,
  });
}

/**
 * Filter organization list.
 */
const { organizationList } = storeToRefs(useOrganizationStore());
const { filterText, setFilterText, filteredData } = useFilterData(
  organizationList,
  ["name"],
);
</script>
