<template>
  <FormBuilder
    class="space-y-4 rounded-2xl bg-darkGray/60 p-8"
    :validation-schema="loginSchema"
    :initial-values="initialValues"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <InputEmail
      id="email"
      name="userEmail"
      :label="$t('email')"
      autocomplete="email"
      required
    />
    <InputPassword
      id="password"
      name="userPassword"
      :label="$t('password')"
      autocomplete="current-password"
      required
    />
    <BaseButton
      class="block w-full text-center"
      type="submit"
      :disabled="isSubmitting"
      >{{ $t("login") }}</BaseButton
    >
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import useAuthStore from "@/stores/auth";
import { loginSchema } from "./validationSchemas.js";
import router from "@/router";

const { login } = useAuthStore();
const error = ref(null);

// TODO: remove this fake data
const initialValues = {
  userEmail: "user@holocron.it",
  userPassword: "Provatest123!",
};

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values) {
  try {
    await login(values);
    router.push({ name: "home" });
  } catch (err) {
    error.value = err;
  }
}
</script>
