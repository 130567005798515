<template>
  <slot :fields="fields" :push="push" :remove="remove" />
</template>

<script setup>
import { useFieldArray } from "vee-validate";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const { fields, push, remove } = useFieldArray(props.name);
</script>
