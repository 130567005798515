<template>
  <FormBuilder
    class="space-y-4"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <InputText id="name" name="name" :label="$t('intentName')" required />
    <div class="flex gap-x-2 [&>*]:grow">
      <InputText id="context" name="context" :label="$t('context')" required />
      <inputText id="type" name="type" :label="$t('type')" required />
    </div>
    <SingleSelect
      id="scopeId"
      name="scopeId"
      :label="$t('scope')"
      :placeholder="$t('selectScope')"
      :options="getScopeListAsOptions"
      required
    />
    <SingleSelect
      id="language"
      name="language"
      :label="$t('language')"
      :placeholder="$t('selectLanguage')"
      :options="getLanguagesAsOptions"
      required
    />
    <TextareaField
      id="description"
      name="description"
      :label="$t('description')"
      required
    />
    <div class="flex gap-x-2 [&>*]:grow">
      <InputText
        id="expressions"
        name="expressions"
        :label="$t('firstExpression')"
        required
      />
      <InputText
        id="responses"
        name="responses"
        :label="$t('firstResponse')"
        required
      />
    </div>
    <InputSwitch
      id="debug_mode"
      name="debug_mode"
      :label="$t('debugMode')"
      :initial-value="true"
    />
    <div class="text-end">
      <BaseButton type="submit" :disabled="isSubmitting">{{
        $t("startCreate")
      }}</BaseButton>
    </div>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import useIntentStore from "@/stores/intent";
import useScopeStore from "@/stores/scope";
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import validationSchema from "./validationSchemas.js";
import { onMounted } from "vue";

const { addIntent } = useIntentStore();
const { fetchScopeList } = useScopeStore();
const { getLanguagesAsOptions } = storeToRefs(useIntentStore());
const { addToast } = useToastStore();
const { closeModal } = useModalStore();
const error = ref(null);
const { t } = useI18n();

onMounted(() => {
  fetchScopeList();
});

const { getScopeListAsOptions } = storeToRefs(useScopeStore());

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values) {
  try {
    values.expressions = [values.expressions];
    values.responses = [values.responses];

    await addIntent(values);
    addToast({
      content: t("itemCreated"),
      variant: "success",
    });
    closeModal();
  } catch (err) {
    error.value = err;
  }
}
</script>
