import * as yup from "yup";

/**
 * @description - Validation schema for channels form
 */
export default yup.object({
  name: yup.string().required(),
  typeId: yup.string().required(),
  scopes: yup.string().required(),
  cidr: yup
    .string()
    .matches(
      "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\/\\d{1,2}(,\\s*\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\/\\d{1,2})*$",
      "Invalid CIDR format",
    )
});
