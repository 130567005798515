import BaseService from "./BaseService";

/**
 * Service class for interacting with the 'channelType' resource in the API.
 * Extends the BaseService class.
 */
class ChannelTypeService extends BaseService {
  /**
   * The resource endpoint for the 'channelType' service.
   * @type {string}
   */
  resource = "channel-type";
}

const channelTypeServiceInstance = new ChannelTypeService();

export default channelTypeServiceInstance;
