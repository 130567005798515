<template>
  <BaseBoxColor variant="gray" class="w-full space-y-2 p-4">
    <GridRow>
      <GridCol :span-md="9" class="flex flex-col space-y-4">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-x-4">
            <BaseBadge
              v-if="debug_mode"
              variant="warning"
              class="bg-opacity-70"
              >{{ $t("debug") }}</BaseBadge
            >
            <h3>org:{{ prefix }}/{{ slug }}</h3>
          </div>
          <div class="flex gap-x-4">
            <BaseButton
              tag="RouterLink"
              :to="{ name: 'scopesSinglePage', params: { id } }"
              :outline="true"
              size="xs"
              >{{ $t("manage") }}</BaseButton
            >
            <BaseButton
              v-if="channelsUp.length"
              @click="handleChannelsList"
              size="xs"
              :outline="true"
              >{{ $t("channelsUp") }}</BaseButton
            >
          </div>
        </div>
        <p class="grow">
          {{ description }}
        </p>
        <div class="flex gap-x-6">
          <div>
            <h4 class="text-sm uppercase text-white/75">
              {{ $t("catalogueIntent") }}
            </h4>
            <strong class="text-xl">{{ catalogueIntent }}</strong>
          </div>
          <div>
            <h4 class="text-sm uppercase text-white/75">
              {{ $t("dynamicIntent") }}
            </h4>
            <strong class="text-xl">{{ dynamicIntent }}</strong>
          </div>
          <div>
            <h4 class="text-sm uppercase text-white/75">
              {{ $t("expression") }}
            </h4>
            <strong class="text-xl">{{ expressionCount }}</strong>
          </div>
          <div>
            <h4 class="text-sm uppercase text-white/75">{{ $t("usage") }}</h4>
            <strong class="text-xl">{{ expressionUsage }}</strong>
          </div>
        </div>
      </GridCol>
      <GridCol :span-md="3">
        <div class="mb-2">
          <h4 class="text-white/75">#{{ Organization.name }}</h4>
        </div>
        <template v-if="channelsDown.length">
          <h5 class="text-sm uppercase text-white/75">
            {{ $t("channelsDown") }}
          </h5>
          <ul>
            <li
              v-for="channel in channelsDown"
              class="flex items-center gap-x-2"
            >
              <BaseCircle variant="danger" />
              {{ channel.name }}
            </li>
          </ul>
        </template>
      </GridCol>
    </GridRow>
    <h6 class="text-end text-white/75">#{{ code }}</h6>
  </BaseBoxColor>
</template>

<script setup>
import useModalStore from "@/stores/modal";
import { useI18n } from "vue-i18n";
import ChannelListModal from "@/components/channels/ChannelListModal.vue";

const props = defineProps({
  id: {
    type: [String, Number],
    required: true,
  },
  code: {
    type: String,
    default: "code",
  },
  prefix: {
    type: String,
    default: "Scope",
  },
  slug: {
    type: String,
    default: "Slug",
  },
  description: {
    type: String,
    default: "",
  },
  debug_mode: {
    type: Boolean,
    default: false,
  },
  Organization: {
    type: Object,
    default: () => ({}),
  },
  catalogueIntent: {
    type: Number,
    default: 0,
  },
  dynamicIntent: {
    type: Number,
    default: 0,
  },
  expressionUsage: {
    type: Number,
    default: 0,
  },
  expressionCount: {
    type: Number,
    default: 0,
  },
  channelsDown: {
    type: Array,
    default: () => [],
  },
  channelsUp: {
    type: Array,
    default: () => [],
  },
});

const modal = useModalStore();
const { t } = useI18n();

/**
 * Handle new organization event.
 */
function handleChannelsList() {
  modal.openModal({
    title: t("channelsUp"),
    component: ChannelListModal,
    props: {
      chennels: props.channelsUp,
    },
  });
}
</script>
