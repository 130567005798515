import { defineStore } from "pinia";
import { IntentService } from "@/api/services";

export default defineStore("intentStore", {
  id: "intent",
  state: () => ({
    intentList: [],
    currentIntentID: null,
    languages: null,
  }),
  actions: {
    /**
     * Fetch the list of intents
     */
    async fetchIntentList() {
      const { intentList } = await IntentService.readAll();
      this.intentList = intentList;
    },
    /**
     * Fetch a single intent
     * @param {Number} id
     */
    async fetchIntent(id) {
      const { intent } = await IntentService.read(id);
      this.currentIntent = intent;

      this.currentIntentID = intent.id;
    },
    /**
     * Fetch languages
     */
    async fetchLanguages() {
      const { languages } = await IntentService.readLanguages();
      this.languages = languages;
    },
    /**
     * Add a new intent
     * @param {Object} payload
     */
    async addIntent(payload) {
      const { intent } = await IntentService.create(payload);
      this.intentList.push(intent);
    },
    /**
     * Update a intent
     * @param {Number} id
     * @param {Object} payload
     */
    async updateIntent(id, payload) {
      const { intent } = await IntentService.update(id, payload);
      this.intentList = this.intentList.map((elm) =>
        elm.id === intent.id ? intent : elm,
      );
    },
    /**
     * Delete a intent
     * @param {Number} id
     */
    async deleteIntent(id) {
      await IntentService.delete(id);
      this.intentList = this.intentList.filter((elm) => elm.id !== id);
    },
    /**
     * Toggle debug mode of a intent
     * @param {Number} id
     */
    async toggleDebug(id) {
      await IntentService.toggleDebug(id);
      this.intentList.find((elm) => elm.id === id).debug_mode =
        !this.intentList.find((elm) => elm.id === id).debug_mode;
    },
    /**
     * Update context of a intent
     * @param {Number} id
     * @param {Object} payload
     */
    async updateContext(id, payload) {
      const { newContext } = await IntentService.updateContext(id, payload);
      this.intentList.find((elm) => elm.id === id).context = newContext;
    },
    /**
     * Add a new expression to an intent
     * @param {Number} id
     * @param {Object} payload
     */
    async addExpression(id, payload) {
      const { newExpression } = await IntentService.createExpression(
        id,
        payload,
      );
      this.intentList
        .find((elm) => elm.id === id)
        .Expressions.push(newExpression);
    },

    /**
     * Update an expression
     * @param {Number} intentID
     * @param {Number} expressionID
     * @param {Object} payload
     * @returns {Promise}
     */
    async updateExpression(intentID, expressionID, payload) {
      const { newExpression } = await IntentService.updateExpression(
        intentID,
        expressionID,
        payload,
      );

      const intent = this.intentList.find((elm) => elm.id === Number(intentID));
      intent.Expressions = intent.Expressions.map((elm) =>
        elm.id === Number(expressionID) ? newExpression : elm,
      );
    },
    /**
     * Delete an expression
     * @param {Number} intentID
     * @param {Number} expressionID
     */
    async deleteExpression(intentID, expressionID) {
      await IntentService.deleteExpression(intentID, expressionID);
      const intent = this.intentList.find((elm) => elm.id === intentID);
      intent.Expressions = intent.Expressions.filter(
        (elm) => elm.id !== expressionID,
      );
    },
    /**
     * Create a response
     * @param {Number} id
     * @param {Object} payload
     */
    async addResponse(id, payload) {
      const { newResponse } = await IntentService.createResponse(id, payload);
      this.intentList.find((elm) => elm.id === id).Responses.push(newResponse);
    },
    /**
     * Update a response
     * @param {Number} intentID
     * @param {Number} responseID
     * @param {Object} payload
     */
    async updateResponse(intentID, responseID, payload) {
      const { newResponse } = await IntentService.updateResponse(
        intentID,
        responseID,
        payload,
      );
      const intent = this.intentList.find((elm) => elm.id === intentID);
      intent.Responses = intent.Responses.map((elm) =>
        elm.id === responseID ? newResponse : elm,
      );
    },
    /**
     * Delete a response
     * @param {Number} intentID
     * @param {Number} responseID
     */
    async deleteResponse(intentID, responseID) {
      await IntentService.deleteResponse(intentID, responseID);
      const intent = this.intentList.find((elm) => elm.id === intentID);
      intent.Responses = intent.Responses.filter(
        (elm) => elm.id !== responseID,
      );
    },
    /**
     * Update permissions of an intent
     * @param {Number} id
     * @param {Object} payload
     */
    async updatePermissions(id, payload) {
      const { new_permissions, failed_permissions } =
        await IntentService.updatePermissions(id, payload);
      const intent = this.intentList.find((elm) => elm.id === id);
      intent.Permissions = new_permissions;

      return failed_permissions;
    },
  },
  getters: {
    /**
     * Get languages as options
     * @param {Object} state
     * @returns {Array}
     */
    getLanguagesAsOptions: (state) => {
      const options = [];
      for (const [key, value] of Object.entries(state.languages)) {
        options.push({
          value,
          label: key,
        });
      }
      return options || [];
    },
    /**
     * Get current intent name
     * @param {Object} state
     * @returns {String}
     */
    getCurrentIntentName: (state) => {
      return state.getCurrentIntent?.name;
    },
    /**
     * Get current intent
     * @param {Object} state
     * @returns {Object}
     */
    getCurrentIntent: (state) => {
      return state.intentList.find((elm) => elm.id === state.currentIntentID);
    },
  },
});
