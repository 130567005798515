<template>
  <div
    v-bind="$attrs"
    :class="
      twMerge(
        `inline-block h-3 w-3 rounded-full`,
        variants[variant],
        sizes[size],
        $attrs.class,
      )
    "
  ></div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  variant: {
    type: String,
    default: "primary",
  },
  size: {
    type: String,
    default: "sm",
  },
});

/**
 * Object containing class names for different variants of the BaseCircle component.
 */
const variants = {
  primary: "bg-primary",
  success: "bg-success",
  danger: "bg-danger",
  warning: "bg-warning",
  gray: "bg-darkGray-200",
  lightGray: "bg-darkGray-100 text-darkGray-200",
};

/**
 * Object containing class names for different sizes of the BaseCircle component.
 */
const sizes = {
  sm: "h-3 w-3",
  md: "h-4 w-4",
  lg: "h-5 w-5",
};
</script>
