import { defineStore } from "pinia";
import { AuthService } from "@/api/services";
import router from "@/router";

export default defineStore("authStore", {
  id: "auth",
  state: () => ({
    token: "",
    user: null,
  }),
  actions: {
    /**
     * Login user.
     * @param {Object} credentials
     */
    async login(credentials) {
      const {
        session: { user },
        auth_token,
      } = await AuthService.login(credentials);
      this.token = auth_token;
      this.user = user;
    },
    /**
     * Request password reset.
     * @param {Object} payload
     */
    async forgotPassword(payload) {
      await AuthService.forgotPassword(payload);
    },
    /**
     * Reset user password.
     * @param {Object} payload
     */
    async resetPassword(payload) {
      await AuthService.resetPassword(payload);
    },
    /**
     * Logout user.
     */
    async logout() {
      await AuthService.logout();
      this.redirectAfterLogout();
    },
    /**
     * Clear user data and redirect to login page.
     */
    async redirectAfterLogout() {
      this.$reset();
      router.push({ name: "login" });
    },
  },
  getters: {
    /**
     * Check if user is logged in.
     * @returns {Boolean}
     */
    isLoggedIn: (state) => !!state.token,
  },
  persist: {
    storage: localStorage,
  },
});
