<template>
  <FormBuilder
    class="space-y-4"
    :validation-schema="validationSchema"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <InputText
      id="expression"
      name="expression"
      :initial-value="expression"
      :label="$t('expression')"
      required
    />
    <div class="text-end">
      <BaseButton type="submit" :disabled="isSubmitting">{{
        $t("startCreate")
      }}</BaseButton>
    </div>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useIntentStore from "@/stores/intent";
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import { createExpressionSchema as validationSchema } from "./validationSchemas.js";

const props = defineProps({
  intentID: {
    type: Number,
    required: true,
  },
});

const { addExpression } = useIntentStore();
const { addToast } = useToastStore();
const { closeModal } = useModalStore();
const error = ref(null);
const { t } = useI18n();

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values) {
  try {
    await addExpression(props.intentID, values);
    addToast({
      content: t("itemCreated"),
      variant: "success",
    });
    closeModal();
  } catch (err) {
    error.value = err;
  }
}
</script>
