<template>
  <component :is="chartType" :data="chartData" :options="options" />
</template>

<script setup>
import { computed } from "vue";
import { Line, Bar, Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  Filler,
  Colors,
} from "chart.js";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  options: {
    type: Object,
    required: false,
    default: () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    }),
  },
  type: {
    type: String,
    required: false,
    default: "Line",
    validator(value) {
      return ["Line", "Bar", "Pie"].includes(value);
    },
  },
  label: {
    type: String,
    required: true,
  },
});

const chartTypes = {
  Line,
  Bar,
  Pie,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  Filler,
  Colors,
);

const chartData = computed(() => {
  return {
    labels: Array.from({ length: props.data.length }, (_, i) => i + 1),
    datasets: [
      {
        label: props.label,
        data: props.data,
        fill: false,
        tension: 0.1,
        backgroundColor: "#00FFAF",
        borderColor: "#124535",
      },
    ],
  };
});

const chartType = computed(() => {
  return chartTypes[props.type];
});
</script>
