<template>
  <FormBuilder
    class="space-y-4 rounded-2xl bg-darkGray/60 p-8"
    :validation-schema="forgotPasswordSchema"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <div class="space-y-2 text-white/75">
      <h3 class="text-lg">
        {{ $t("resetPassword") }}
      </h3>
      <p>
        {{ $t("resetPasswordDescription") }}
      </p>
    </div>
    <InputEmail
      id="email"
      name="email"
      :label="$t('email')"
      autocomplete="email"
      required
    />
    <BaseButton
      class="block w-full text-center"
      type="submit"
      :disabled="isSubmitting"
      >{{ $t("resetPassword") }}</BaseButton
    >
    <BaseBoxColor v-if="success" class="w-full" variant="success">{{
      success
    }}</BaseBoxColor>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import useAuthStore from "@/stores/auth";
import { useI18n } from "vue-i18n";
import { forgotPasswordSchema } from "./validationSchemas.js";

const { t } = useI18n();
const { forgotPassword } = useAuthStore();
const error = ref(null);
const success = ref(null);

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values, ctx) {
  try {
    await forgotPassword(values);
    success.value = t("resetPasswordSuccess");
    ctx.resetForm();
  } catch (err) {
    error.value = err;
  }
}
</script>
