<template>
  <BaseField v-slot="slotProps">
    <div class="relative">
      <input
        class="pr-10"
        :type="visible ? 'text' : 'password'"
        v-bind="slotProps"
      />
      <BaseIcon
        class="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer text-white/50"
        @click="visible = !visible"
        :name="visible ? 'visibility_off' : 'visibility'"
      />
    </div>
  </BaseField>
</template>

<script setup>
import { ref } from "vue";
import BaseField from "./BaseField.vue";

const visible = ref(false);
</script>
