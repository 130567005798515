import * as yup from "yup";

/**
 * @description - Validation schema for scope form
 */
export default yup.object({
  prefix: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string(),
  debug_mode: yup.boolean(),
  organizationId: yup.number(),
});
