import { defineStore } from "pinia";
import { ChannelService } from "@/api/services";
import useChannelTypeStore from "./channelType";

export default defineStore("channelStore", {
  id: "channel",
  state: () => ({
    channelList: [],
    channel: null,
  }),
  actions: {
    /**
     * Fetch the list of channels
     */
    async fetchChannelList() {
      const { getChannelTypeById } = useChannelTypeStore();
      const { channelList } = await ChannelService.readAll();

      this.channelList = channelList.map((channel) => {
        return {
          ...channel,
          type: getChannelTypeById(channel.ChannelTypeId)?.name,
        };
      });
    },
    /**
     * Add a new channel
     * @param {Object} payload
     */
    async addChannel(payload) {
      payload.scopes = [payload.scopes];

      const { channel } = await ChannelService.create(payload);
      this.channelList.push(channel);
    },
    /**
     * Update a channel
     * @param {Number} id
     * @param {Object} payload
     */
    async updateChannel(id, payload) {
      const { channel } = await ChannelService.update(id, payload);
      const { getChannelTypeById } = useChannelTypeStore();

      this.channelList = this.channelList.map((elm) =>
        elm.id === channel.id
          ? {
              ...channel,
              type: getChannelTypeById(channel.ChannelTypeId)?.name,
            }
          : elm,
      );
    },
    /**
     * Delete a channel
     * @param {Number} id
     */
    async deleteChannel(id) {
      await ChannelService.delete(id);
      this.channelList = this.channelList.filter((elm) => elm.id !== id);
    },
    /**
     * Toggle enabled state of a channel
     * @param {Number} id
     */
    async toggleEnabled(id) {
      const { channel } = await ChannelService.toggleEnabled(id);
      this.channelList.find((elm) => elm.id === id).enabled = channel.enabled;
    },
  },
});
