import useAuthStore from "@/stores/auth.js";

/**
 * Middleware function for authentication.
 * Checks if the user is logged in and allows access to the requested route.
 * If the user is not logged in and the route is not an exceptional route, it redirects to the login page.
 *
 * @param {Object} to - The target route object being navigated to.
 * @param {Object} from - The current route object being navigated away from.
 * @param {Function} next - The function to be called to resolve the navigation.
 * @returns {void}
 */
export default async (to, from, next) => {
  let exceptionalRoutes = ["login", "forgotPassword", "resetPassword"];
  let isGoingExceptionalRoutes = exceptionalRoutes.includes(to.name);

  if (isGoingExceptionalRoutes || useAuthStore().isLoggedIn) {
    return next();
  } else {
    return next({ name: "login" });
  }
};
