<template>
  <div
    v-bind="$attrs"
    :class="
      twMerge(
        `inline-block rounded-lg border border-white/10 p-2`,
        variants[variant],
        $attrs.class,
      )
    "
  >
    <slot />
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  variant: {
    type: String,
    default: "primary",
    validator: (value) =>
      [
        "primary",
        "success",
        "danger",
        "warning",
        "gray",
        "lightGray",
        "green",
        "orange",
        "blue",
        "yellow",
        "magenta",
      ].includes(value),
  },
});

/**
 * Object containing class names for different variants of the BaseBoxColor component.
 */
const variants = {
  primary: "bg-primary-300",
  success: "bg-success/40",
  danger: "bg-danger/40",
  warning: "bg-warning/40",
  gray: "bg-darkGray-200",
  lightGray: "bg-darkGray-100 text-darkGray-200",
  green: "bg-green-100",
  orange: "bg-orange-100",
  blue: "bg-blue-100",
  yellow: "bg-yellow-100",
  magenta: "bg-magenta-100",
};
</script>
