<template>
  <main class="flex h-screen w-full items-center justify-center">
    <div class="w-full max-w-lg space-y-4 p-4">
      <BaseLogo class="mx-auto w-28" />
      <div class="text-center text-xs text-darkGray-100">
        {{ version }}
      </div>
      <RouterView />
    </div>
  </main>
</template>

<script setup>
import { RouterView } from "vue-router";
const version = APP_VERSION;
</script>
