<template>
  <FormBuilder
    class="space-y-4"
    :validation-schema="validationSchema"
    @submit="onSubmit"
    @input="
      () => {
        error = null;
        warning = null;
      }
    "
    v-slot="{ isSubmitting, meta }"
  >
    <TextareaField
      id="slugArray"
      name="slugArray"
      :initial-value="stringPermissions"
      :label="$t('permissions')"
      :rows="4"
    />
    <div class="text-end">
      <BaseButton type="submit" :disabled="isSubmitting || !meta.valid">{{
        $t("save")
      }}</BaseButton>
    </div>
    <BaseBoxColor v-if="warning" class="w-full" variant="warning">{{
      warning
    }}</BaseBoxColor>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useIntentStore from "@/stores/intent";
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import { editPermissionsSchema as validationSchema } from "./validationSchemas.js";

const props = defineProps({
  intentID: {
    type: Number,
    required: true,
  },
  permissions: {
    type: Array,
    default: () => [],
  },
});

const stringPermissions = props.permissions.map((elm) => elm.slug).join(", ");

const { updatePermissions } = useIntentStore();
const { addToast } = useToastStore();
const { closeModal } = useModalStore();

const error = ref(null);
const warning = ref(null);

const { t } = useI18n();

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values, ctx) {
  try {
    const failedPermissions = await updatePermissions(props.intentID, values);

    if (!failedPermissions?.length) {
      addToast({
        content: t("itemUpdated"),
        variant: "success",
      });
      closeModal();
      return;
    }

    warning.value = t("permissionsNotUpdated", {
      permissions: failedPermissions.join(", "),
    });
    ctx.resetForm();
  } catch (err) {
    error.value = err;
  }
}
</script>
