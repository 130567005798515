<template>
  <div
    v-bind="$attrs"
    :class="
      twMerge(
        `inline-flex items-center justify-center rounded-full bg-darkGray-100 font-code font-semibold tracking-wider text-darkGray-200 uppercase`,
        sizes[size],
        $attrs.class,
      )
    "
  >
    <template v-if="initials?.length">{{ initials }}</template>
    <span class="material-symbols-rounded" v-else>person</span>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  initials: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: "md",
    validator: (value) => ["sm", "md"].includes(value),
  },
});

/**
 * Object containing class names for different sizes of the BaseAvart component.
 */
const sizes = {
  sm: "w-8 h-8 text-sm",
  md: "w-10 h-10 text-base",
};
</script>

<style lang="postcss" scoped>
.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
</style>