<template>
  <BaseBoxColor class="inline-flex w-64 items-center gap-x-2 text-sm">
    <slot />
    <BaseCloseButton class="ml-auto" size="sm" @click="emit('onClosing')" />
  </BaseBoxColor>
</template>

<script setup>
const emit = defineEmits(["onClosing"]);
</script>
