<template>
  <section class="mb-8 flex items-center justify-between">
    <div class="flex items-center gap-x-8">
      <h1>{{ $t("intents") }}</h1>
      <BaseSearch @on-searching="setFilterText" />
    </div>
    <BaseButton @click="handleNewIntent">{{ $t("addNew") }}</BaseButton>
  </section>
  <BaseDataList
    class="space-y-6"
    :tot-data="filteredData.length"
    :is-searching="!!filterText"
  >
    <IntentCard
      v-for="intent in filteredData"
      v-bind="intent"
      :key="intent.id"
    />
  </BaseDataList>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useModalStore from "@/stores/modal";
import useIntentStore from "@/stores/intent";
import IntentCard from "@/components/intents/IntentCard.vue";
import IntentForm from "@/components/intents/IntentForm.vue";
import useFilterData from "@/composables/useFilterData";

const modal = useModalStore();
const { t } = useI18n();

/**
 * Handle new itent.
 */
function handleNewIntent() {
  modal.openModal({
    title: t("newIntent"),
    component: IntentForm,
  });
}

/**
 * Filter scope list.
 */
const { intentList } = storeToRefs(useIntentStore());
const { filterText, setFilterText, filteredData } = useFilterData(intentList, [
  "name",
]);
</script>
