<template>
  <nav>
    <ul class="flex flex-col space-y-1">
      <li>
        <BaseMenuItem tag="RouterLink" :to="{ name: 'home' }">{{
          $t("home")
        }}</BaseMenuItem>
      </li>
      <li>
        <BaseMenuItem tag="RouterLink" :to="{ name: 'organizationsIndex' }">{{
          $t("organizations")
        }}</BaseMenuItem>
      </li>
      <li>
        <BaseMenuItem tag="RouterLink" :to="{ name: 'scopesIndex' }">{{
          $t("scopes")
        }}</BaseMenuItem>
      </li>
      <li>
        <BaseMenuItem tag="RouterLink" :to="{ name: 'channelsIndex' }">{{
          $t("channels")
        }}</BaseMenuItem>
      </li>
      <li>
        <BaseMenuItem tag="RouterLink" :to="{ name: 'intentsIndex' }">{{
          $t("intents")
        }}</BaseMenuItem>
      </li>
    </ul>
  </nav>
</template>

<script setup></script>
