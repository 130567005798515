<template>
  <component :is="tag" :class="twMerge(`mx-auto w-full px-4`, sizes[size])">
    <slot />
  </component>
</template>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  tag: {
    type: String,
    default: "div",
  },
  size: {
    type: String,
    default: "md",
    validator: (value) => ["sm", "md", "lg"].includes(value),
  },
});

/**
 * Object containing the different sizes of the grid container.
 */
const sizes = {
  sm: "max-w-[62.5rem]",
  md: "max-w-[82.5rem]",
  lg: "max-w-[92.5rem]",
};
</script>
