import { useApi } from "@/api/useAPI.js";

/**
 * Base service class for interacting with the API.
 */
export default class BaseService {
  /**
   * The API instance used by the service.
   * @type {Object}
   */
  api = useApi();

  /**
   * The resource endpoint for the service.
   * @type {string}
   */
  resource = "";

  /**
   * Fetches all items from the specified resource.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async readAll() {
    return this.api.get(`/${this.resource}`);
  }

  /**
   * Fetches a single item from the specified resource by its ID.
   * @param {string} id - The ID of the item to be fetched.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async read(id) {
    return this.api.get(`/${this.resource}/${id}`);
  }

  /**
   * Creates a new item in the specified resource.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async create(payload) {
    return this.api.post(`/${this.resource}`, payload);
  }

  /**
   * Updates an item in the specified resource by its ID.
   * @param {string} id - The ID of the item to be updated.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async update(id, payload) {
    return this.api.put(`/${this.resource}/${id}`, payload);
  }

  /**
   * Deletes an item from the specified resource by its ID.
   * @param {string} id - The ID of the item to be deleted.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async delete(id) {
    return this.api.delete(`/${this.resource}/${id}/delete`);
  }
}
