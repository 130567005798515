<template>
  <GridContainer>
    <RouterView />
  </GridContainer>
</template>

<script setup>
import { RouterView } from "vue-router";
import useScopeStore from "@/stores/scope";

const { fetchScopeList } = useScopeStore();
await fetchScopeList();
</script>
