<template>
  <label class="inline-flex cursor-pointer items-center">
    <input
      v-model="value"
      class="accent-primary"
      type="radio"
      v-bind="$attrs"
    />
    <span v-if="label" class="ms-2">{{ label }}</span>
  </label>
</template>

<script setup>
import { useField } from "vee-validate";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  label: { type: String },
  name: { type: String, required: true },
  modelValue: { type: [String, Number] },
  initialValue: { type: [String, Number] },
});

const { value } = useField(() => props.name, undefined, {
  syncVModel: true,
  initialValue: props.initialValue || props.modelValue,
});
</script>
