<template>
  <main
    class="grow rounded-t-3xl bg-darkGray-400 py-8 flex flex-col"
    :class="$attrs.class"
    :style="$attrs.style"
  >
    <div class="page-content flex-grow">
      <RouterView v-slot="{ Component, route }">
        <Transition name="fade" mode="out-in">
          <Component :key="route.name" :is="Component" class="flex-grow" />
        </Transition>
      </RouterView>
    </div>
    <div class="mt-6 text-center text-xs text-darkGray-100 font-medium">
      Lory v{{ version }}
    </div>
  </main>
</template>

<script setup>
const version = APP_VERSION;
</script>
