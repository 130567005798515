/**
 * @desc: This file contains the data for the preview component
 */

/**
 * Phrases to be displayed in the preview component
 */
const phrases = [
  "laboris nisi ut aliquip ex ea commodo consequat",
  "Lorem ipsum dolor sit amet",
  "consectetur adipisicing elit",
  "Excepteur sint occaecat cupidatat non proident",
  "sed do eiusmod tempor incididunt",
  "ut labore et dolore magna aliqua",
  "quis nostrud exercitation ullamco",
  "in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
  "Ut enim ad minim veniam",
  "Duis aute irure dolor in reprehenderit",
  "sunt in culpa qui officia deserunt mollit anim id est laborum",
];

/**
 * Positions for the preview component
 */
const positions = [
  "top-3 left-2",
  "top-1 left-4",
  "top-4 left-2",
  "bottom-1 right-1",
  "bottom-2 right-2",
  "bottom-3 right-1",
];

/**
 * Colors for the preview component
 */
const colors = ["green", "orange", "blue", "yellow", "magenta"];

/**
 * Widths for the preview component
 */
const width = ["max-w-[20rem]", "max-w-lg", "max-w-[30rem]"];

/**
 * Fade effects for the preview component
 */
const fadeEffects = [
  "fade-up-left",
  "fade-up-right",
  "fade-down-left",
  "fade-down-right",
  "fade-down",
  "fade-top",
];

export { phrases, positions, colors, width, fadeEffects };
