import BaseService from "./BaseService";

/**
 * Service class for interacting with the 'channel' resource in the API.
 * Extends the BaseService class.
 */
class ChannelService extends BaseService {
  /**
   * The resource endpoint for the 'channel' service.
   * @type {string}
   */
  resource = "channel";

  /**
   * Read the scopes for a channel by its ID.
   * @param {number} id The ID of the channel.
   * @returns {Promise} A promise that resolves to the response data.
   */
  async readChannelScopes(id) {
    return this.api.get(`${this.resource}/${id}/scopes`);
  }

  /**
   * Toggles the enabled state of a channel.
   * @param {number} id The ID of the channel to toggle.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async toggleEnabled(id) {
    return this.api.post(`${this.resource}/${id}/toggle`);
  }
}

const channelServiceInstance = new ChannelService();

export default channelServiceInstance;
