import BaseService from "./BaseService";

/**
 * Service class for interacting with the 'intent' resource in the API.
 * Extends the BaseService class.
 */
class IntentService extends BaseService {
  /**
   * The resource endpoint for the 'intent' service.
   * @type {string}
   */
  resource = "intent";

  /**
   * Fetch all languages
   * @returns {Promise}
   */
  async readLanguages() {
    return this.api.get(`${this.resource}/find/languages`);
  }

  /**
   * Toggle debug mode of a intent
   * @param {Number} id
   * @returns {Promise}
   */
  async toggleDebug(id) {
    return this.api.patch(`${this.resource}/${id}/debug`);
  }

  /**
   * Update context of a intent
   * @param {Number} id
   * @param {Object} payload
   * @returns {Promise}
   */
  async updateContext(id, payload) {
    return this.api.patch(`${this.resource}/${id}/context`, payload);
  }

  /**
   * Add a new expression to an intent
   * @param {Number} id
   * @param {Object} payload
   * @returns {Promise}
   */
  async createExpression(id, payload) {
    return this.api.post(`${this.resource}/${id}/expression`, payload);
  }

  /**
   * Update an expression
   * @param {Number} id
   * @param {Object} payload
   * @returns {Promise}
   */
  async updateExpression(intentID, expressionID, payload) {
    return this.api.patch(
      `${this.resource}/${intentID}/expression/${expressionID}`,
      payload,
    );
  }

  /**
   * Delete an expression
   * @param {Number} id
   * @returns {Promise}
   */
  async deleteExpression(intentID, expressionID) {
    return this.api.delete(
      `${this.resource}/${intentID}/expression/${expressionID}`,
    );
  }

  /**
   * Create a response
   * @param {Number} id
   * @param {Object} payload
   * @returns {Promise}
   */
  async createResponse(id, payload) {
    return this.api.post(`${this.resource}/${id}/response`, payload);
  }

  /**
   * Update a response
   * @param {Number} intentID
   * @param {Number} responseID
   * @param {Object} payload
   * @returns {Promise}
   */
  async updateResponse(intentID, responseID, payload) {
    return this.api.patch(
      `${this.resource}/${intentID}/response/${responseID}`,
      payload,
    );
  }

  /**
   * Delete a response
   * @param {Number} intentID
   * @param {Number} responseID
   * @returns {Promise}
   */
  async deleteResponse(intentID, responseID) {
    return this.api.delete(
      `${this.resource}/${intentID}/response/${responseID}`,
    );
  }

  /**
   * Update permissions of an intent
   * @param {Number} intentID
   * @param {Object} payload
   * @returns {Promise}
   */
  async updatePermissions(intentID, payload) {
    payload.slugArray = payload.slugArray
      ?.split(",")
      ?.map((slug) => slug.trim())
      ?.filter((slug) => slug !== "");

    if (!payload.slugArray) payload.slugArray = [];

    return this.api.post(
      `${this.resource}/${intentID}/permission/set`,
      payload,
    );
  }
}

const intentServiceInstance = new IntentService();

export default intentServiceInstance;
