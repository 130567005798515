<template>
  <label class="inline-flex cursor-pointer items-center">
    <slot
      v-bind="$attrs"
      type="checkbox"
      :checked="checked"
      @change="handleChange"
    />
    <span v-if="label" class="ms-2">{{ label }}</span>
  </label>
</template>

<script setup>
import { useField } from "vee-validate";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  label: { type: String },
  name: { type: String, required: true },
  modelValue: {
    type: null,
  },
  value: {},
  initialValue: {},
  checkedValue: { type: [String, Number, Boolean] },
  uncheckedValue: { type: [String, Number, Boolean] },
});

const { handleChange, checked } = useField(() => props.name, undefined, {
  type: "checkbox",
  syncVModel: true,
  initialValue: props.initialValue,
  checkedValue: props.checkedValue,
  uncheckedValue: props.uncheckedValue,
});
</script>
