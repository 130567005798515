/**
 * Autoloads components from the "./ui" directory and registers them in the Vue app.
 * @module autoloadComponents
 */
import _ from "lodash";

export default {
  /**
   * Installs the autoloaded components in the Vue app.
   * @param {object} app - The Vue app instance.
   */
  install(app) {
    const componentFiles = import.meta.glob(
      ["./ui/*.vue", "./grid/*.vue", "./form-fields/!(Base*).vue"],
      {
        eager: true,
      },
    );

    Object.entries(componentFiles).forEach(([path, m]) => {
      const componentName = _.upperFirst(
        _.camelCase(
          path
            .split("/")
            .pop()
            .replace(/\.\w+$/, ""),
        ),
      );

      app.component(componentName, m.default);
    });
  },
};
