import BaseService from "./BaseService";
import { OrganizationService } from "@/api/services";
import { ChannelService } from "@/api/services";

/**
 * Service class for interacting with the 'scope' resource in the API.
 * Extends the BaseService class.
 */
class ScopeService extends BaseService {
  /**
   * The resource endpoint for the 'scope' service.
   * @type {string}
   */
  resource = "scope";

  /**
   * Fetches all items from the specified resource.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async readAll() {
    const { scopeList: scopes } = await this.api.get(`/${this.resource}`);

    const scopeList = await Promise.all(
      scopes.map(async (scope) => {
        const relatedData = await this.relatedData(scope.id);
        return { ...scope, ...relatedData };
      }),
    );

    return { scopeList };
  }

  /**
   * Fetches a single item from the specified resource by its ID.
   * @param {string} id - The ID of the item to be fetched.
   * @returns {Promise} A Promise that resolves to the response data.
   */
  async read(id) {
    const { scope: scopeData } = await this.api.get(`/${this.resource}/${id}`);
    const relatedData = await this.relatedData(id);

    const scope = { ...scopeData, ...relatedData };
    return { scope };
  }

  async relatedData(id) {
    const { channels_down: channelsDown } = await this.readChannelsDown(id);
    const { channels_up: channelsUp } = await this.readChannelsUp(id);
    const { dims } = await this.readDims(id);

    return { channelsDown, channelsUp, dims };
  }

  /**
   * Toggles the debug mode of a scope.
   * @param {number} id The ID of the scope to toggle debug mode for.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async toggleDebug(id) {
    return this.api.post(`${this.resource}/${id}/toggle-debug`);
  }

  /**
   * Gets the intents for a scope.
   * @param {number} id The ID of the scope to get the intents for.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async readIntents(id) {
    return this.api.get(`${this.resource}/${id}/intents`);
  }

  /**
   * Gets the most used intent for a scope.
   * @param {number} id The ID of the scope to get the most used intents for.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async readMostUsedIntent(id) {
    return this.api.get(`${this.resource}/${id}/intents/most-used`);
  }

  /**
   * Gets the channels for a scope.
   * @param {number} id The ID of the scope to get the channels for.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async readChannels(id) {
    return this.api.get(`${this.resource}/${id}/channels`);
  }

  /**
   * Gets down channels for a scope.
   * @param {number} id The ID of the scope.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async readChannelsDown(id) {
    return this.api.get(`${this.resource}/${id}/channels/down`);
  }

  /**
   * Gets up channels for a scope.
   * @param {number} id The ID of the scope.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async readChannelsUp(id) {
    return this.api.get(`${this.resource}/${id}/channels/up`);
  }

  /**
   * Gets the dims for a scope.
   * @param {number} id The ID of the scope to get the dims for.
   * @returns {Promise} A promise that resolves with the response data.
   */
  async readDims(id) {
    return this.api.get(`${this.resource}/${id}/dims`);
  }
}

const scopeServiceInstance = new ScopeService();

export default scopeServiceInstance;
