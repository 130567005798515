<template>
  <div
    class="inline-flex min-w-72 items-center overflow-hidden rounded-xl border border-white/10 bg-darkGray"
    :class="{'ring ring-primary-300' : focused}"
  >
    <BaseIcon name="search" class="pl-3" />
    <input
      v-model="search"
      type="text"
      :placeholder="$t(`search`)"
      class="grow rounded-none border-none pl-3 focus:outline-none focus:ring-0"
      @focus="focused = true"
      @blur="focused = false"
    />
    <BaseCloseButton
      v-show="search !== ''"
      class="mr-2"
      variant="danger"
      size="sm"
      @click="search = ''"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const emit = defineEmits(["onSearching"]);

const search = ref("");
const focused = ref(false);

watch(search, (value) => {
  emit("onSearching", value);
});
</script>
