<template>
  <section class="space-y-4">
    <div class="flex items-center justify-between">
      <h2 class="text-base uppercase text-white/75">
        {{ $t("mostUsedIntents") }}
      </h2>
      <BaseButton
        :outline="true"
        size="xs"
        tag="RouterLink"
        :to="{ name: 'intentsIndex' }"
        >{{ $t("viewAll") }}</BaseButton
      >
    </div>
    <BaseDataList :totData="intents.length" tag="div" class="space-y-4">
      <template #no-data>
        <p class="text-center">{{ $t("noData") }}</p>
      </template>
      <UsedIntentCard v-for="intent in intents" v-bind="intent" />
    </BaseDataList>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { ScopeService } from "@/api/services";
import UsedIntentCard from "./UsedIntentCard.vue";

const props = defineProps({
  scopeID: {
    type: Number,
    required: true,
  },
});

const intents = ref(null);
const { intent: data } = await ScopeService.readMostUsedIntent(props.scopeID);
intents.value = data;
</script>
