<template>
  <div class="space-y-4">
    <p>Do you want to proceed with this operation?</p>
    <div class="flex justify-center gap-x-4">
      <BaseButton variant="gray" @click="$emit('closeModal')"
        >Cancel</BaseButton
      >
      <BaseButton @click="resolveFunc(true)">Yes, I do</BaseButton>
    </div>
  </div>
</template>

<script setup>
import useModalStore from "@/stores/modal";
const { resolveFunc } = useModalStore();
</script>
