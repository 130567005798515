<template>
  <button
    v-bind="$attrs"
    type="button"
    :class="
      twMerge(
        `flex cursor-pointer items-center justify-center rounded-full`,
        variants[variant],
        sizes[size],
        $attrs.class,
      )
    "
  >
    <BaseIcon name="close_small" style="font-size: inherit" />
  </button>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { twMerge } from "tailwind-merge";

defineProps({
  variant: {
    type: String,
    default: "default",
    validator: (value) => ["default", "danger"].includes(value),
  },
  size: {
    type: String,
    default: "md",
    validator: (value) => ["sm", "md", "lg"].includes(value),
  },
});

/**
 * Object containing class names for different variants of the BaseCloseButton component.
 */
const variants = {
  default: "bg-white/10",
  danger: "bg-danger/20 text-danger",
};

/**
 * Object containing class names for different sizes of the BaseCloseButton component.
 */
const sizes = {
  sm: "h-6 w-6 text-2xl",
  md: "h-8 w-8 text-4xl",
  lg: "h-10 w-10 text-5xl",
};
</script>
