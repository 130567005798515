<template>
  <FormBuilder
    v-if="route.query.token"
    class="space-y-4 rounded-2xl bg-darkGray/60 p-8"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <div class="space-y-2 text-white/75">
      <h3 class="text-lg">
        {{ $t("resetPassword") }}
      </h3>
      <p>
        {{ $t("typeNewPassword") }}
      </p>
      <ul class="space-y-1">
        <li
          v-for="(requirement, index) in passwordRequirements"
          :key="index"
          class="flex items-center space-x-2"
          :class="{ 'font-medium text-success': requirement.isValid }"
        >
          <span
            class="inline-flex h-5 w-5 items-center justify-center rounded-full"
            :class="`${requirement.isValid ? 'bg-success/10' : 'bg-white/10'} `"
          >
            <BaseIcon name="done" style="font-size: inherit" />
          </span>
          <span>
            {{ requirement.text }}
          </span>
        </li>
      </ul>
    </div>
    <InputPassword
      id="password"
      v-model="formData.password"
      name="newPassword"
      :label="$t('newPassword')"
      autocomplete="current-password"
      required
    />
    <InputPassword
      id="passwordConfirmation"
      v-model="formData.passwordConfirmation"
      name="confirmPassword"
      :label="$t('confirmPassword')"
      autocomplete="current-password"
      required
    />
    <BaseButton
      class="block w-full text-center"
      type="submit"
      :disabled="isSubmitting || !isPasswordValid"
    >
      {{ $t("resetPassword") }}
    </BaseButton>
    <BaseBoxColor v-if="success" class="w-full" variant="success">{{
      success
    }}</BaseBoxColor>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">
      {{ error }}
    </BaseBoxColor>
  </FormBuilder>
  <h2 v-else class="text-center">
    {{ $t("tokenMissing") }}
  </h2>
</template>

<script setup>
import { ref, computed } from "vue";
import useAuthStore from "@/stores/auth";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const route = useRoute();
const { resetPassword } = useAuthStore();
const error = ref(null);
const success = ref(null);
const { t } = useI18n();

const formData = ref({
  password: "",
  passwordConfirmation: "",
});

const passwordRequirements = computed(() => [
  {
    text: t("passwordRequirements.length"),
    isValid: formData.value.password?.length >= 8,
  },
  {
    text: t("passwordRequirements.numbers"),
    isValid: /\d/.test(formData.value.password),
  },
  {
    text: t("passwordRequirements.uppercase"),
    isValid: /[A-Z]/.test(formData.value.password),
  },
  {
    text: t("passwordRequirements.specialCharacter"),
    isValid: /[^a-zA-Z0-9]/.test(formData.value.password),
  },
  {
    text: t("passwordRequirements.match"),
    isValid:
      formData.value.password.length &&
      formData.value.password === formData.value.passwordConfirmation,
  },
]);

const isPasswordValid = computed(() =>
  passwordRequirements.value.every((requirement) => requirement.isValid),
);

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values, ctx) {
  try {
    values.token = route.query.token;
    await resetPassword(values);
    success.value = t("resetPasswordSuccessConfirmed");
    ctx.resetForm();
  } catch (err) {
    error.value = err;
  }
}
</script>
