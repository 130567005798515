<template>
  <FormBuilder
    class="space-y-4"
    :validation-schema="validationSchema"
    @submit="onSubmit"
    @input="error = null"
    v-slot="{ isSubmitting }"
  >
    <InputText id="name" name="name" :label="$t('name')" required />
    <InputText id="type" name="type" :label="$t('type')" required />
    <div class="text-end">
      <BaseButton type="submit" :disabled="isSubmitting">{{
        $t("startCreate")
      }}</BaseButton>
    </div>
    <BaseBoxColor v-if="error" class="w-full" variant="danger">{{
      error
    }}</BaseBoxColor>
  </FormBuilder>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import useScopeStore from "@/stores/scope";
import validationSchema from "./validationSchemas.js";

const props = defineProps({
  scopeID: {
    type: Number,
    required: true,
  },
});

const { addToast } = useToastStore();
const { closeModal } = useModalStore();
const { addDimToScope } = useScopeStore();

const error = ref(null);
const { t } = useI18n();

/**
 * handle form submit
 * @param {Object} values
 * @param {Function} resetForm
 */
async function onSubmit(values) {
  try {
    values.scopes = [props.scopeID];
    await addDimToScope(props.scopeID, values);

    addToast({
      content: t("itemCreated"),
      variant: "success",
    });
    closeModal();
  } catch (err) {
    error.value = err;
  }
}
</script>
