import useAuthStore from "@/stores/auth";
/**
 * Middleware - if user lost authentication (401) it gets kicked out
 */
const middleware401 = async (error) => {
  const { status } = error.request;
  if (status === 401) {
    return Promise.reject({
      name: "Permission denied",
      message: "You are not allowed to do that.",
    });
  }
  if (status === 419) {
    const auth = useAuthStore();
    await auth.redirectAfterLogout();
  }
  return Promise.reject(error);
};

export { middleware401 as default };
