<template>
  <GridContainer>
    <RouterView />
  </GridContainer>
</template>

<script setup>
import { RouterView } from "vue-router";
import useIntentStore from "@/stores/intent";

const { fetchIntentList, fetchLanguages } = useIntentStore();

await fetchIntentList();
await fetchLanguages();
</script>
