<template>
  <div class="max-w-[50%]">
    <div class="flex items-start gap-x-2" :class="{ 'flex-row-reverse': user }">
      <BaseAvatar
        v-if="user"
        class="h-8 w-8 shrink-0"
        :initials="userInitials"
      />
      <BaseChatLogo v-else class="w-8 shrink-0" />
      <div>
        <BaseBoxColor
          :class="user ? 'rounded-tr-none' : 'rounded-tl-none'"
          :variant="user ? 'primary' : 'gray'"
        >
          {{ message }}
        </BaseBoxColor>
        <time class="mt-1 block text-sm" :class="{ 'text-end': user }">{{
          time
        }}</time>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
  user: {
    type: Object,
  },
  time: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
});

const userInitials = computed(() => {
  let user = props.user;
  let initials = '';

  if (user?.name?.length && user?.surname?.length) initials = `${user?.name[0]}${user?.surname[0]}`
  if (user?.name?.length && !user?.surname?.length) initials = `${user?.name[0]}${user?.name[1]}`
  if (!user?.name?.length && user?.surname?.length) initials = `${user?.surname[0]}${user?.surname[1]}`
  if (!user?.name?.length && !user?.surname?.length && user?.username?.length) initials = `${user?.username[0]}${user?.username[1]}`

  return initials;
})
</script>
