import { createApp } from "vue";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import i18nInstance from "./helpers/i18n";
import vClickOutside from "click-outside-vue3";
import { DateTime } from "luxon";

import "./assets/base.css";
import "aos/dist/aos.css";

import App from "./App.vue";
import router from "./router";
import VueLogger from "vuejs3-logger";
import autoloadComponents from "./components/autoloadComponents.js";

//configureValidation();

const isProduction = import.meta.env.PROD;
if (!isProduction) {
  console.log("--- NOT IN PRODUCTION ENVIRONMENT ---");
  console.log("--- Logger setted on debug level ---");
  // console.log(import.meta.env )
}

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

DateTime.local().setLocale(i18nInstance.global.locale);

const app = createApp(App);
const head = createHead(App);

app.use(VueLogger, loggerOptions);
app.use(createPinia().use(piniaPluginPersistedstate));
app.use(router);
app.use(i18nInstance);
app.use(vClickOutside);
app.use(autoloadComponents);
app.use(head)

app.mount("#app");
