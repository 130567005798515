<template>
  <form @submit.prevent="onSubmitForm" class="flex gap-x-4">
    <input
      v-model.trim="formData.message"
      class="w-full"
      type="text"
      ref="input"
      :placeholder="$t(`chat.formPlaceholder`)"
      required
    />
    <BaseButton type="submit"
      >{{ $t("chat.send") }} <BaseIcon name="send"
    /></BaseButton>
  </form>
</template>

<script setup>
import { ref, onMounted } from "vue";

const input = ref(null);

onMounted(() => {
  input.value.focus();
});

const emit = defineEmits(["onSubmitForm"]);
const formData = ref({
  message: "",
});

/**
 * on submit form emit event and reset form
 */
function onSubmitForm() {
  emit("onSubmitForm", formData.value);
  formData.value.message = "";
}
</script>
