<template>
  <component
    :is="tag"
    class="inline-flex items-center rounded-full bg-primary/5 text-primary backdrop-blur-2xl after:absolute after:w-full after:h-full after:d-block after:border after:rounded-full after:border-white after:opacity-10"
  >
    <div
      class="h-full aspect-square rounded-full bg-primary flex items-center justify-center shadow-[0_0_4px] shadow-primary/60"
    >
      <img class="h-full min-w-8 p-[20%]" :src="logo" :alt="$t(`lory.chatActionLabel`)" />
    </div>
    <p
      class="shrink-0 font-code font-medium text-nowrap chat-label"
      v-html="$t(`lory.chatActionLabel`)"
      ref="label"
      :style="showLabel ? `--width: ${labelWidth}px` : '--width: 0'"
    ></p>
  </component>
</template>

<script setup>
import logo from "@/assets/lory-face.svg";
import { ref, onMounted } from "vue";

defineProps({
  tag: {
    type: String,
    default: "div",
  },
  showLabel: {
    type: Boolean,
    default: false,
  },
});

const label = ref(null);
const labelWidth = ref(null);

onMounted(() => {
  labelWidth.value = label?.value?.clientWidth + 20 || 0;
});
</script>

<style lang="postcss" scoped>
.chat-label {
  transition: width .25s;
  width: var(--width);
  overflow: hidden;
}
</style>
