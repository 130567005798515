<template>
  <section class="space-y-4">
    <div class="item-center flex justify-between">
      <h2 class="text-base uppercase text-white/75">
        {{ $t("responses") }}
      </h2>
      <BaseButton :outline="true" size="xs" @click="addResponse">{{
        $t("add")
      }}</BaseButton>
    </div>
    <BaseBoxColor class="block" variant="gray" v-for="response in responses">
      <div class="flex justify-between">
        <p>
          {{ response.text }}
        </p>
        <div class="flex gap-x-2">
          <div
            class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-primary/20 text-primary"
            @click="editResponse(response.id)"
          >
            <BaseIcon class="text-base" name="edit" />
          </div>
          <div
            class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-danger/20 text-danger"
            @click="handleDeleteResponse(props.id, response.id)"
          >
            <BaseIcon class="text-base" name="delete" />
          </div>
        </div>
      </div>
    </BaseBoxColor>
  </section>
</template>

<script setup>
import useModalStore from "@/stores/modal";
import useToastStore from "@/stores/toast";
import useIntentStore from "@/stores/intent";
import { useI18n } from "vue-i18n";
import IntentResponseCreateForm from "./IntentResponseCreateForm.vue";
import IntentResponseEditForm from "./IntentResponseEditForm.vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  responses: {
    type: Array,
    default: () => [],
  },
});

const { addToast } = useToastStore();
const { openModal, openConfirmModal } = useModalStore();
const { deleteResponse } = useIntentStore();
const { t } = useI18n();

/**
 * open add response modal
 */
function addResponse() {
  openModal({
    title: t("addResponse"),
    component: IntentResponseCreateForm,
    props: {
      intentID: props.id,
    },
  });
}

/**
 * open edit response modal
 * @param {Number} id
 */
function editResponse(id) {
  openModal({
    title: t("editResponse"),
    component: IntentResponseEditForm,
    props: {
      intentID: props.id,
      response: props.responses.find((e) => e.id === id).text,
      responseID: id,
      roundedResponse: props.responses.find((e) => e.id === id).rounded,
    },
  });
}

/**
 * delete response
 * @param {Number} id
 */
async function handleDeleteResponse(intentID, responseID) {
  const response = await openConfirmModal({
    title: t("deleteResponse"),
  });

  if (!response) return;

  // delete response
  try {
    await deleteResponse(intentID, responseID);
    addToast({
      variant: "success",
      content: t("itemDeleted"),
    });
  } catch (error) {
    addToast({
      variant: "danger",
      content: t("deleteErrorMessage"),
    });
  }
}
</script>
