<template>
  <header ref="header" :class="{'hide-bg' : !showBackground}">
    <GridContainer class="flex items-center justify-between py-4">
      <RouterLink :to="{ name: 'home' }">
        <BaseLogo class="w-20" :key="logoKey" />
      </RouterLink>
      <UserProfile />
    </GridContainer>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { RouterLink } from "vue-router";
import UserProfile from "@/components/user/UserProfile.vue";

const props = defineProps({
  showBackground: {
    type: Boolean,
  },
});

const logoKey = ref(1);

const header = ref(null);

defineExpose({ header });

setInterval(() => {
  logoKey.value++;
}, 10000);
</script>

<style lang="postcss" scoped>
header {
  @apply fixed w-full top-0;

  &::after {
    @apply block absolute w-full h-[100px] left-0 top-[100%] rounded-t-[2rem];
    box-shadow: 0 -70px 0 60px #242422;
    content: "";
    display: block;
    pointer-events: none;
    z-index: -1;
    opacity: 1;
    transition: all .2s;
  }

  &.hide-bg {
    &::after {
      opacity: 0;
    }
  }
}
</style>
