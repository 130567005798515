<template>
  <section class="mb-8 flex items-center justify-between">
    <div class="flex items-center gap-x-8">
      <BaseBreadcrumbs />
    </div>
    <InputSwitch
      name="debug_mode"
      :label="$t('debugMode')"
      :initial-value="scope.debug_mode"
      @change="toggleDebug(params.id)"
    />
  </section>
  <section>
    <GridRow class="gap-y-6">
      <GridCol :span-md="6">
        <ScopeInformationCard v-bind="scope" />
      </GridCol>
      <GridCol :span-md="6">
        <ScopeUsageChart v-bind="scope" />
      </GridCol>
      <GridCol :span-md="6">
        <MostUsedIntents :scopeID="scope.id" />
      </GridCol>
      <GridCol :span-md="6">
        <ChannelsGateways :scopeID="scope.id" />
      </GridCol>
      <GridCol :span-md="12">
        <DynamicIntentModules :scopeID="scope.id" :dims="scope.dims" />
      </GridCol>
    </GridRow>
  </section>
</template>

<script setup>
import useScopeStore from "@/stores/scope";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import MostUsedIntents from "@/components/intents/MostUsedIntents.vue";
import ChannelsGateways from "@/components/channels/ChannelsGateways.vue";
import DynamicIntentModules from "@/components/dims/DynamicIntentModules.vue";
import ScopeInformationCard from "@/components/scopes/ScopeInformationCard.vue";
import ScopeUsageChart from "@/components/scopes/ScopeUsageChart.vue";

const { fetchScope, toggleDebug } = useScopeStore();
const { params } = useRoute();
await fetchScope(params.id);
const { getCurrentScope: scope } = storeToRefs(useScopeStore());
</script>
