<template>
  <section class="mb-8 flex items-center justify-between">
    <div class="flex items-center gap-x-8">
      <h1>{{ $t("scopes") }}</h1>
      <BaseSearch @on-searching="setFilterText" />
    </div>
    <BaseButton @click="handleNewScope">{{ $t("addNew") }}</BaseButton>
  </section>
  <BaseDataList
    class="space-y-6"
    :tot-data="filteredData.length"
    :is-searching="!!filterText"
  >
    <ScopeCard v-for="scope in filteredData" :key="scope.id" v-bind="scope" />
  </BaseDataList>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useModalStore from "@/stores/modal";
import useScopeStore from "@/stores/scope";
import ScopeCard from "@/components/scopes/ScopeCard.vue";
import ScopeForm from "@/components/scopes/ScopeForm.vue";
import useFilterData from "@/composables/useFilterData";

const modal = useModalStore();
const { t } = useI18n();

/**
 * Handle new scope event.
 */
function handleNewScope() {
  modal.openModal({
    title: t("newScope"),
    component: ScopeForm,
  });
}

/**
 * Filter scope list.
 */
const { scopeList } = storeToRefs(useScopeStore());
const { filterText, setFilterText, filteredData } = useFilterData(scopeList, [
  "slug",
]);
</script>
